import "../AuthStyle.css";
import { Form, Input, message, Button, Typography } from "antd";
import { useContext } from "react";
import { AppContext } from "../../../context/AppContextProvider";
import { userLogin } from "../../../network/network";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const { Title } = Typography;

const Login = () => {
  const { setAuthUser, setFullPageLoading } = useContext(AppContext);
  let navigate = useNavigate();
  const { t } = useTranslation();

  const onFinish = (values) => {
    setFullPageLoading(true);
    userLogin(values)
      .then((response) => {
        if (response.data?.success) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("profile", JSON.stringify(response.data.user));
          setAuthUser(response.data.user);
          navigate("/home");
        } else {
          let error = response.response;
          if (error?.status === 422) {
            message.error(error.data.message);
          } else if (error?.status === 500) {
            let errorMessages = Object.values(error.data.data.errors);
            errorMessages.forEach(function (key, value) {
              let temp = Object.values(key);
              message.error(temp[0]);
            });
          } else {
            message.error(t("something_went_wrong"));
          }
        }
      })
      .finally(() => {
        setFullPageLoading(false);
      });
  };
  return (
    <div className="login_container">
      {/* <div className="lang_button">
        <Radio.Group
          value={appConfig.direction}
          onChange={(e) => {
            setAppConfigWrapper({ direction: e.target.value });
          }}
          buttonStyle="solid"
          defaultValue="ltr"
        >
          <Radio.Button value="ltr">English</Radio.Button>
          <Radio.Button value="rtl">عربي</Radio.Button>
        </Radio.Group>
      </div> */}
      <div className="login_container">
        <div className="login_form_container">
          <div className="right">
            <img src="/images/truck.jpg" alt="truck logo" />
            {/* <div className="right_form">
              <Title level={5}>{t("dont_have_account")}</Title>
              <Button>
                <Link to="/register">{t("sign_up")}</Link>
              </Button>
            </div> */}
          </div>
          <div className="left">
            <Form
              onFinish={onFinish}
              name="login-user-form"
              layout="vertical"
              className="form_container"
            >
              <Title level={2} className="title">
                {t("login")}
              </Title>
              <Form.Item
                label={t("email")}
                name="email"
                value="email"
                rules={[
                  {
                    required: true,
                    message: t("validate_email_invalid"),
                  },
                  { type: "email", message: "Email is not valid!" },
                ]}
              >
                <Input className="login_input" />
              </Form.Item>
              <Form.Item
                label={t("password")}
                name="password"
                value="password"
                rules={[
                  {
                    required: true,
                    message: t("validate_password_required"),
                  },
                ]}
              >
                <Input.Password className="login_input" />
              </Form.Item>
              <div className="btn_div">
                <Form.Item>
                  <Button size="large" type="primary" htmlType="submit">
                    {t("login")}
                  </Button>
                </Form.Item>
              </div>
              {/* <Link to="/forgot"> {t("forgot_password")} </Link> */}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
