import React, { useContext, useEffect, useState } from 'react';
import { Timeline, Button, Card, Popconfirm, Tag } from 'antd';
import { CheckCircleTwoTone, ClockCircleOutlined, DeleteOutlined, DeleteTwoTone, EditOutlined, FileTextOutlined, PlusCircleOutlined, UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from "dayjs/plugin/relativeTime";
import { AppContext } from "../../context/AppContextProvider";
import { notes } from '../../network/network';
dayjs.extend(relativeTime);
dayjs.extend(utc);

const Notes = ({ entityType, entityId, refresh, onEditNote, onDeleteNote }) => {
    const { authUser } = useContext(AppContext);
    const [notesData, setNotesData] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        fetchNotes();
    }, [page, refresh]);

    const fetchNotes = async () => {
        setLoading(true);
        try {
            const response = await notes(entityType, entityId, page);
            if (response.data && response.data.data.notes.length > 0) {
                setNotesData(response.data.data.notes);
                setHasMore(response.data.data.pagination.total_pages > response.data.data.pagination.current_page);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Failed to fetch notes:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    const noteTimelineItem = (note) => {
        return {
            label: <span style={{
                fontSize: '14px', fontWeight: 'bold', color: '#555'
            }}>{dayjs(note.created_at).fromNow()}</span>,
            dot: <FileTextOutlined style={{ color: 'grey' }} />,
            children: (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <></>
                        {note.created_by.id === authUser.id && (
                            <div>
                                <Button
                                    type="link"
                                    icon={<EditOutlined />}
                                    onClick={() => onEditNote(note)}
                                >
                                    Edit
                                </Button>
                                <Popconfirm
                                    title="Are you sure to delete this entity? All related data will be deleted."
                                    onConfirm={() => onDeleteNote(note.id)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <DeleteTwoTone style={{ padding: 6, fontSize: "18px" }}
                                        twoToneColor="#ff0000"
                                        title="Delete Entity"
                                    >
                                    </DeleteTwoTone>
                                </Popconfirm>
                            </div>
                        )}
                    </div>
                    <p style={{ fontSize: '14px', color: '#666', marginBottom: '5px' }}>
                        <UserOutlined /> {note.created_by?.name} added a note.
                    </p>
                    <div>
                        <p className="paper-texture">
                            {note.description}
                        </p>
                    </div>
                </div>
            )
        };
    };

    return (
        <>
            <div style={{ textAlign: 'left' }}>
                <h2 style={{ borderBottom: '2px solid #eee', paddingBottom: '10px' }}>Notes</h2>
            </div>
            <Timeline style={{ width: '100%' }} mode="left" items={notesData.map(note => (
                noteTimelineItem(note)
            ))} />
            {hasMore && (
                <div style={{ textAlign: 'center' }}>
                    <Button onClick={handleLoadMore} loading={loading}>
                        Load More
                    </Button>
                </div>
            )}
        </>
    );
};

export default Notes;
