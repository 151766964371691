import React from 'react';
import { Card, List, Typography } from 'antd';
import dayjs from 'dayjs'; // Ensure dayjs is installed and imported if you're using it for date formatting

const { Text } = Typography;

// Assuming getStatusTag is a function defined elsewhere, import it or pass it as a prop if necessary

const ContractsHistory = ({ contractsHistory, getStatusTag, cardStyle }) => {
  return (
    <div style={{ width: '30%', overflow: 'auto', maxHeight: '400px', ...cardStyle }}>
      <List
        header={<div><Text strong>Contracts History</Text></div>}
        dataSource={contractsHistory}
        renderItem={item => (
          <List.Item
            style={{ cursor: 'pointer' }}
            onMouseEnter={e => e.currentTarget.style.backgroundColor = '#f5f5f5'}
            onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}
            onClick={() => console.log(`Contract clicked: ${item.contract}`)}
          >
            <List.Item.Meta
              title={<Text>{item.contract} {getStatusTag(item.status)}</Text>}
              description={
                <>
                  <Text>{dayjs(item.date).format('MMMM Do, YYYY')}</Text>
                </>
              }
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default ContractsHistory;
