import React, { useEffect, useState } from 'react';
import { Button, Card, Divider, Tag, Typography, Input, Select, Form } from 'antd';
import { CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined, UserOutlined } from '@ant-design/icons';
import { BuildingIcon } from '../../../../../components/CustomIcons';
import { updateCustomer } from '../../../../../network/network';
import NotificationWithIcon from '../../../../../components/NotificationWithIcon';
import ImageUpload from '../../../../../components/ImageUpload';
const { Text } = Typography;
const { Option } = Select;

const CustomerProfile = ({ customer, cardStyle, tags }) => {
    const [editForm] = Form.useForm();
    const [editMode, setEditMode] = useState(false);
    const [image, setImage] = useState(false);
    const [customerData, setCustomerData] = useState({
        ...customer,
    });

    useEffect(() => {
        setCustomerData({
            ...customer,
        });
    }, [customer])

    const handleEdit = () => {
        setEditMode(true);
        setImage(customerData.picture);
        editForm.setFieldsValue({
            id: customerData.id,
            company_name: customerData.company_name,
            type: customerData.type,
            status: customerData.status,
            tags: customerData.tags,
        });
    };

    const handleCancel = () => {
        editForm.resetFields();
        setEditMode(false);
    };


    const handleAPIResponse = (response) => {
        if (response.success === true) {
            NotificationWithIcon("success", `Customer updated successfully`, `Customer updated Successfully`);
            editForm.resetFields();
            setCustomerData({ ...response.data.customer });
            console.log(response.data.customer)
        } else {
            response.data.errors.forEach(function (key, value) {
                let temp = Object.values(key);
                NotificationWithIcon("error", "Something Went Wrong", temp[0]);
            });
        }
    };

    const handleSave = async () => {
        setEditMode(false);
        try {
            const values = await editForm.validateFields();
            const formData = new window.FormData();
            Object.keys(values).forEach(key => {
                if (values[key] !== undefined && !Array.isArray(values[key])) {
                    formData.append(key, values[key]);
                } else if (Array.isArray(values[key])) {
                    values[key].forEach((tag, index) => {
                        formData.append(`${key}[${index}]`, tag);
                    });
                }
            });
            if (image && image instanceof File) {
                formData.append("picture", image);
            }
            const response = await updateCustomer(formData, customer.id);
            handleAPIResponse(response.data);
        } catch (error) {
            console.error('Failed to save data', error);
            // Handle errors (e.g., show an error message)
        }
    };

    const handleAddTag = () => {
        setCustomerData({ ...customerData, tags: [...customerData.tags, ''] });
    };

    const handleRemoveTag = (index) => {
        const newTags = [...customerData.tags];
        newTags.splice(index, 1);
        setCustomerData({ ...customerData, tags: newTags });
    };

    return (
        <Card style={{ width: '100%', overflow: 'hidden', ...cardStyle }}>
            <Form form={editForm} onFinish={handleSave} layout="vertical">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* Image Column */}
                        <div style={{ marginRight: '15px' }}>
                            {editMode ? (
                                <ImageUpload
                                    name="customer_picture"
                                    callbackSetImageFile={setImage}
                                    image={image}
                                />
                            ) : (customerData.picture ? (
                                <img
                                    src={customerData.picture}
                                    alt="customer pictuer"
                                    style={{ width: '80px', height: '80px', borderRadius: '50%', border: '2px solid #f0f0f0' }}
                                />
                            ) : (
                                customerData.type === 'Individual' ? (
                                    <UserOutlined style={{ fontSize: "50px" }} />
                                ) : (
                                    <BuildingIcon fontSize={"50px"} />
                                )
                            ))}
                        </div>
                        {/* Info Column */}
                        <div>
                            {editMode ? (
                                <Form.Item name={'company_name'} label="Customer Name" rules={[{ required: true, message: 'Please input the name' }]}>
                                    <Input />
                                </Form.Item>
                            ) : (
                                <Text>{customerData.company_name}</Text>
                            )}
                        </div>
                    </div>
                    <div>
                        {editMode ? (
                            <>
                                <div><Button onClick={handleSave} type="primary" style={{ marginRight: '10px' }}>
                                    <SaveOutlined /> Save
                                </Button>
                                    <Button onClick={handleCancel}>
                                        <CloseOutlined /> Cancel
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <Button onClick={handleEdit} type="primary">
                                <EditOutlined /> Edit Customer
                            </Button>
                        )}
                    </div>
                </div>
                <Divider style={{ margin: '10px 0' }} />
                {/* Lower Section for Additional Data */}
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 10px 10px 10px' }}>
                    {/* <Text>Customer Type: {editMode ? <Input value={customerData.type} onChange={(e) => setCustomerData({ ...customerData, type: e.target.value })} /> : customerData.type}</Text> */}


                    <Text>Customer Type: {editMode ?
                        <Form.Item name={'type'} rules={[{ required: true, message: 'Please select a type' }]}><Select style={{ width: 120 }}>
                            <Option value="Individual">Individual</Option>
                            <Option value="Company">Company</Option>
                        </Select>
                        </Form.Item>
                        : customerData.type}</Text>
                    <div>
                        Tags: {editMode ? (
                            <>
                                {customerData?.tags?.map((tag, index) => (
                                    <div key={index} style={{ display: 'flex' }}>
                                        <Form.Item name={['tags', index]} rules={[{ required: true, message: 'Please select the tag' }]}>
                                            <Select style={{ width: 120, marginRight: '5px' }}>
                                                {tags.map((tag) => (
                                                    <Option key={tag.id} value={tag.id}>{tag.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Button onClick={() => handleRemoveTag(index)} type="danger" icon={<DeleteOutlined style={{ color: 'red' }} />} danger></Button>
                                    </div>
                                ))}
                                <Button onClick={handleAddTag} type="dashed">+ Add Tag</Button>
                            </>
                        ) : customerData?.tags?.map(tagId => <Tag key={tagId}>{tags?.find(tag => tag.id === tagId)?.name}</Tag>)}
                    </div>
                    <div>
                        Status: {editMode ? (
                            <Form.Item name={'status'} rules={[{ required: true, message: 'Please select a status' }]}>
                                <Select style={{ width: 120 }}>
                                    <Option value="Active">Active</Option>
                                    <Option value="Inactive">Inactive</Option>
                                </Select>
                            </Form.Item>
                        ) : (
                            <Tag color={customerData.status === 'Active' ? 'green' : 'volcano'}>{customerData.status}</Tag>
                        )}
                    </div>
                </div>
            </Form>
        </Card>
    );
};

export default CustomerProfile;
