import React, { useState, useEffect } from "react";
import { Card, Button, Table, Input, Tag, Form, Row, Col, Upload, DatePicker, Popconfirm, Modal } from "antd";
import { DeleteTwoTone, DownloadOutlined, EditFilled, EyeFilled, MinusCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { addMultipleDocuments, deleteDocument, getCustomerContracts, getDocuments, updateDocument } from "../network/network";
import { PageHeader } from "@ant-design/pro-layout";
import NotificationWithIcon from "./NotificationWithIcon";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);


const Documents = ({ entityType, entityId, isExpiryDate = false }) => {
    const [loading, setLoading] = useState(false);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [documentsData, setDocumentsData] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState({ title: null, document: null, expiry_date: null });
    const [pagination, setPagination] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [form] = Form.useForm();
    const [editForm] = Form.useForm();

    const [documents, setDocuments] = useState([{ title: null, document: null, expiry_date: null }]);

    const addDocument = () => {
        setDocuments([...documents, { title: null, document: null, expiry_date: null }]);
    };

    const removeDocument = (index) => {
        const updatedDocuments = [...documents];
        updatedDocuments.splice(index, 1);
        setDocuments(updatedDocuments);
    };

    const fetchData = async () => {
        try {
            const documentsRespone = await getDocuments({
                page: currentPage,
                search: searchTerm,
            }, entityType, entityId);
            setDocumentsData(documentsRespone.data.data.documents);
            const paginationData = documentsRespone.data.data.pagination;
            setPagination({
                current: paginationData.current_page,
                pageSize: paginationData.per_page,
                total: paginationData.total,
                showSizeChanger: false,
            });
        } catch (error) {
            console.error("Fetching data failed:", error);
        } finally {
            setIsDataLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    const debouncedFetchData = debounce(() => {
        fetchData();
    }, 500);

    useEffect(() => {
        debouncedFetchData();
        // Cleanup to cancel the debounced call if component unmounts or the value changes
        return () => debouncedFetchData.cancel();
    }, [searchTerm]);


    const showForm = (shouldShow) => {
        setIsFormVisible(shouldShow);
    };


    const handleAPIResponse = (response, action) => {
        if (response.success === true) {
            NotificationWithIcon("success", `Customer Document(s) ${action} successfully`, `Customer Document(s) ${action} Successfully`);
            fetchData();
        } else {
            response.data.errors.forEach(function (key, value) {
                let temp = Object.values(key);
                NotificationWithIcon("error", "Something Went Wrong", temp[0]);
            });
        }
    };

    const handleAdd = async () => {
        try {
            setLoading(true);
            const values = await form.validateFields();
            const formData = new FormData();
            values.documents.forEach((item, index) => {
                formData.append(`documents[${index}][title]`, item.title);
                formData.append(`documents[${index}][expiry_date]`, item.expiry_date ? dayjs.utc(item.expiry_date).local().format('YYYY-MM-DD') : '');
                if (item.document) {
                    formData.append(`documents[${index}][document]`, item.document.file);
                }
            });
            const response = await addMultipleDocuments(formData, entityType, entityId);
            handleAPIResponse(response.data, 'added');
            setDocuments([{ title: null, document: null, expiry_date: null }]);
            form.resetFields();
            setIsFormVisible(false);
        } catch (error) {
            console.error("Validation or API call failed:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = async () => {
        try {
            setLoading(true);
            const values = await editForm.validateFields();
            console.log(values);
            const formData = new FormData();
            formData.append(`title`, values.title);
            formData.append(`expiry_date`, values.expiry_date ? dayjs.utc(values.expiry_date).local().format('YYYY-MM-DD') : '');
            if (values.document) {
                formData.append(`document`, values.document.file);
            }
            const response = await updateDocument(formData, values.id);
            handleAPIResponse(response.data, 'updated');
            setSelectedDocument({ title: null, document: null, expiry_date: null });
            editForm.resetFields();
            setModalVisible(false);
        } catch (error) {
            console.error("Validation or API call failed:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = (documentId) => {
        deleteDocument(documentId)
            .then((response) => {
                let data = response.data || response.response.data;
                if (data.success === true) {
                    NotificationWithIcon("success", "Document Deleted", data.message);
                    fetchData();
                } else {
                    // Error
                    NotificationWithIcon("error", "Something Went Wrong", data.message);
                }
            })
            .catch((info) => {
                // Error
                NotificationWithIcon(
                    "error",
                    "Something Went Wrong",
                    info.response.data.message
                );
            });
    };

    const handleContractsTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
        setCurrentPage(pagination.current);
    };

    const handleCancel = () => {
        setSelectedDocument({ title: null, document: null, expiry_date: null });
        editForm.resetFields();
        setModalVisible(false);
    };

    const handleEditModal = (record) => {
        editForm.resetFields();
        editForm.setFieldsValue({
            id: record.id,
            title: record.title,
            expiry_date: record.expiry_date ? dayjs(record.expiry_date, "YYYY-MM-DD") : null,
        });
        setSelectedDocument(record);
        setModalVisible(true);
    };

    const tableColumns = [
        {
            title: "#",
            key: "index",
            width: "5%",
            render: (text, record, index) => {
                return ((pagination.current - 1) * pagination.pageSize) + index + 1;
            },
        },
        {
            title: "Title",
            dataIndex: "title",
        },
        {
            title: "Action",
            align: "center",
            width: "140px",
            render: (record) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            type="link"
                            onClick={() => {
                                window.open(record.url, '_blank');
                            }}
                            title="Download Document"
                            style={{
                                padding: 6,
                                fontSize: "18px",
                                borderRadius: "5px",
                                backgroundColor: "rgba(82, 196, 26, 0.2)",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <DownloadOutlined style={{ color: "#52c41a" }} />
                        </Button>
                        <Button
                            type="link"
                            onClick={() => {
                                handleEditModal(record);
                            }}
                            title="Edit Contact"
                            style={{
                                padding: 6,
                                fontSize: "18px",
                                borderRadius: "5px",
                                backgroundColor: "rgba(82, 196, 26, 0.2)",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <EditFilled style={{ color: "#52c41a" }} />
                        </Button>
                        <Popconfirm
                            title="Are you sure to delete this entity?"
                            onConfirm={() => handleDelete(record.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteTwoTone
                                style={{
                                    padding: 6,
                                    fontSize: "18px",
                                    borderRadius: "5px",
                                    backgroundColor: "rgba(255, 0, 0, 0.2)",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                twoToneColor="#ff0000"
                                title="Delete Entity"
                            />
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];


    if (isExpiryDate) {
        tableColumns.splice(2, 0, {
            title: "Expiry Date",
            dataIndex: "expiry_date",
        });
    }

    return (
        <>
            <Card style={{ marginBottom: "20px" }}>
                <PageHeader
                    style={{ padding: "0" }}
                    title={`${entityType} Documents`}
                    ghost={false}
                    extra={[
                        <div key={"btns"}>
                            <Button style={{ display: isFormVisible ? 'none' : 'block' }} type="primary" onClick={() => { showForm(true) }} key="add-customer-documents">
                                + Add Documents
                            </Button>
                            <Button style={{ display: isFormVisible ? 'block' : 'none' }} type="primary" onClick={() => { showForm(false) }} key="cancel-customer-documents">
                                Cancel
                            </Button>
                        </div>
                    ]}
                />
                <div style={{ display: isFormVisible ? 'block' : 'none' }}>
                    <Form form={form} onFinish={handleAdd} layout="vertical">
                        {documents.map((doc, index) => (
                            <Row key={index} gutter={16} style={{ marginBottom: 8 }}>
                                <Col span={6}>
                                    <Form.Item name={['documents', index, 'title']} label="Title" rules={[{ required: true, message: 'Please input the title' }]}>
                                        <Input placeholder="Enter Title" />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item name={['documents', index, 'document']} label="Document" rules={[{ required: true, message: 'Please upload the Document' }]}>
                                        <Upload beforeUpload={() => false} showUploadList={true} maxCount={1}>
                                            <Button icon={<UploadOutlined />}>Upload File</Button>
                                        </Upload>
                                    </Form.Item>
                                </Col>
                                {
                                    isExpiryDate && (
                                        <Col span={6}>
                                            <Form.Item
                                                name={['documents', index, 'expiry_date']}
                                                label="Document Expiry Date"
                                                rules={[{ required: true, message: "Expiry Date is required" }]}
                                                hasFeedback
                                            >
                                                <DatePicker format="YYYY-MM-DD" allowClear style={{ width: "100%" }} />
                                            </Form.Item>
                                        </Col>
                                    )
                                }
                                <Col span={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {index > 0 && (
                                        <Button type="link" danger onClick={() => removeDocument(index)} icon={<MinusCircleOutlined />}>
                                            Remove
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={addDocument} block icon={<PlusOutlined />}>
                                Add Document
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Upload Documents
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Card>
            <Card>
                <Input
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    onPressEnter={() => fetchData()}
                    style={{ marginBottom: 20, width: '50%' }}
                />
                <Table columns={tableColumns} dataSource={documentsData} loading={isDataLoading} rowKey="id" pagination={pagination} onChange={handleContractsTableChange} bordered />
            </Card>
            {/* Edit Modal */}
            <Modal
                open={modalVisible}
                title="Edit Document"
                onOk={handleEdit}
                onCancel={handleCancel}
                width={400}
                footer={[
                    <Button key="back" loading={loading} onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        loading={loading}
                        onClick={handleEdit}
                    >
                        Update Contact
                    </Button>,
                ]}
            >
                <Form
                    name="edit-form"
                    onFinish={handleEdit}
                    layout="vertical"
                    style={{ width: "100%", margin: "0" }}
                    form={editForm}
                >
                    <Row gutter={16} style={{ marginBottom: 8 }}>
                        <Col span={10}>
                            <Form.Item name={'title'} label="Title" rules={[{ required: true, message: 'Please input the title' }]}>
                                <Input placeholder="Enter Title" />
                            </Form.Item>
                        </Col>
                        {
                            isExpiryDate && (
                                <Col span={12}>
                                    <Form.Item
                                        name={'expiry_date'}
                                        label="Document Expiry Date"
                                        rules={[{ required: true, message: "Expiry Date is required" }]}
                                        hasFeedback
                                    >
                                        <DatePicker format="YYYY-MM-DD" allowClear style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                            )
                        }
                    </Row>
                    <Form.Item name="id" style={{ display: "none" }}>
                        <input type="hidden" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default Documents;
