import React, { useState, useEffect } from "react";
import {
    Card,
    Form,
    Input,
    Button,
    Table,
    Popconfirm,
    message,
    Row,
    Col,
    Space,
    DatePicker,
    Select,
    Drawer,
    Upload,
    Radio,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
    addEmployee,
    deleteEmployee,
    updateEmployee,
    getEmployees,
    rawDataEmployee,
} from "../../../../network/network";
import NotificationWithIcon from "../../../../components/NotificationWithIcon";
import { DeleteTwoTone, EditTwoTone, EyeFilled, UploadOutlined, UserOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import moment from "moment";
import ImageUpload from "../../../../components/ImageUpload";
import { useNavigate } from "react-router-dom";
const Employees = () => {
    const ADD_EMPLOYEE = "Add Employee";
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [reloadTableData, setReloadTableData] = useState(false);
    const [employeeRawData, setEmployeeRawData] = useState([]);
    const [employeeData, setEmployeeData] = useState([]);
    const [drawerTitle, setDrawerTitle] = useState(ADD_EMPLOYEE);
    const [employeesForm] = Form.useForm();
    const navigate = useNavigate();

    useEffect(() => {
        rawDataEmployee().then((response) => {
            setEmployeeRawData(response.data.data);
        });
    }, []);

    useEffect(() => {
        setIsDataLoading(true);
        getEmployees()
            .then((response) => {
                setEmployeeData(response.data.data.employees);
            })
            .finally(() => {
                setIsDataLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [reloadTableData]);

    const showDrawer = () => {
        setDrawerTitle(ADD_EMPLOYEE);
        setImage(false);
        setDrawerVisible(true);
    };

    const handleAPIResponse = (response, type) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
            setReloadTableData(!reloadTableData);
            if (type === ADD_EMPLOYEE) {
                NotificationWithIcon(
                    "success",
                    "Employee Added",
                    "Employee added successfully!"
                );
            } else {
                NotificationWithIcon(
                    "success",
                    "Employee Updated",
                    "Employee updated successfully!"
                );
            }
        } else {
            NotificationWithIcon(
                "error",
                "Something Went Wrong",
                data.message
            );
        }
        employeesForm.resetFields();
        setDrawerVisible(false);
    };

    const handleAddEdit = () => {
        employeesForm
            .validateFields()
            .then((values) => {
                setLoading(true);
                const formData = new window.FormData();
                Object.keys(values).forEach(key => {
                    if (values[key] !== undefined) {
                        formData.append(key, values[key]);
                    }
                });
                if (image && image instanceof File) {
                    formData.append("image", image);
                }
                if (drawerTitle === ADD_EMPLOYEE) {
                    addEmployee(formData)
                        .then((response) => handleAPIResponse(response, ADD_EMPLOYEE))
                        .catch((info) => {
                            console.log("API Failed:", info.response);
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                } else {
                    updateEmployee(formData, values.id)
                        .then((response) => handleAPIResponse(response, null))
                        .catch((info) => {
                            console.log("API Failed:", info.response);
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                }
            })
            .catch((info) => {
                console.log("API Failed:", info);
            });
    };

    const handleCancel = () => {
        employeesForm.resetFields();
        setImage(false);
        setDrawerVisible(false);
    };

    const handleEditDrawer = (record) => {
        employeesForm.resetFields();
        setImage(record.image);
        setDrawerTitle("Edit Employee: " + record.name);
        employeesForm.setFieldsValue({
            id: record.id,
            name: record.name,
            email: record.email,
            dob: moment(record.dob),
            contact: record.contact,
            department_id: record.department.id,
            designation_id: record.designation.id,
            status: record.status === "Active" ? "active" : "inactive",
        });
        setDrawerVisible(true);
    };

    const handleDelete = (id) => {
        deleteEmployee(id)
            .then((response) => {
                let data = response.data || response.response.data;
                if (data.success === true) {
                    setReloadTableData(!reloadTableData);
                    NotificationWithIcon("success", "Employee Deleted", data.message);
                } else {
                    NotificationWithIcon("error", "Something Went Wrong", data.message);
                }
            })
            .catch((info) => {
                NotificationWithIcon(
                    "error",
                    "Something Went Wrong",
                    info.response.data.message
                );
            });
    };


    const handleViewDetails = (employee) => {
        navigate(`/employees/${employee.id}`);
    };

    const tableColumns = [
        {
            title: "#",
            key: "index",
            width: "5%",
            render: (text, record, index) => {
                return <>{index + 1}</>;
            },
        },
        {
            title: "Picture",
            align: "center",
            render: (record) => {
                console.log(record)
                return (
                    <>
                        {record.image ? (
                            <img
                                src={record.image}
                                alt="employee pictuer"
                                style={{ maxWidth: "50px", borderRadius: "50%" }}
                            />
                        ) : (
                            <UserOutlined style={{ fontSize: "50px" }} />
                        )}
                    </>
                );
            }
        },
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "DOB",
            dataIndex: "dob",
        },
        {
            title: "Contact",
            dataIndex: "contact",
        },
        {
            title: "Email",
            dataIndex: "email",
        },
        {
            title: "Department",
            render: (record) => <>{record.department.name}</>,
        },
        {
            title: "Designation",
            render: (record) => (
                <>{record.designation ? record.designation.name : "-"}</>
            ),
        },
        {
            title: "Action",
            align: "center",
            width: "100px",
            render: (record) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            type="link"
                            onClick={() => {
                                handleViewDetails(record);
                            }}
                            title="View Details"
                            style={{
                                padding: 6,
                                fontSize: "18px",
                                borderRadius: "5px",
                                backgroundColor: "rgba(82, 196, 26, 0.2)",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <EyeFilled style={{ color: "#52c41a" }} />
                        </Button>
                        <Button
                            type="link"
                            onClick={() => {
                                handleEditDrawer(record);
                            }}
                            title="Edit record"
                            style={{
                                padding: 6,
                                fontSize: "18px",
                                borderRadius: "5px",
                                backgroundColor: "rgba(24, 144, 255, 0.2)",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <EditTwoTone twoToneColor="#1890ff" />
                        </Button>
                        <Popconfirm
                            title="Are you sure to delete this entity?"
                            onConfirm={() => handleDelete(record.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteTwoTone
                                style={{
                                    padding: 6,
                                    fontSize: "18px",
                                    borderRadius: "5px",
                                    backgroundColor: "rgba(255, 0, 0, 0.2)",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                twoToneColor="#ff0000"
                                title="Delete Entity"
                            />
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <Card style={{ marginBottom: "20px" }}>
                <PageHeader
                    style={{ padding: "0" }}
                    title="Employees"
                    ghost={false}
                    extra={[
                        <Button type="primary" onClick={showDrawer} key="add-employee">
                            + Add Employees
                        </Button>,
                    ]}
                />
            </Card>
            <Card>
                <Table
                    columns={tableColumns}
                    dataSource={employeeData}
                    loading={isDataLoading}
                    rowKey="id"
                    pagination={false}
                    bordered
                />
            </Card>
            <Drawer
                title={drawerTitle}
                placement="right"
                onClose={handleCancel}
                open={drawerVisible}
                width={"calc(100% - 200px)"}
            >
                <Form
                    name="add-form"
                    onFinish={handleAddEdit}
                    layout="vertical"
                    style={{ width: "100%", margin: "0" }}
                    form={employeesForm}
                >
                    <Row
                        gutter={{
                            xs: 8,
                            sm: 16,
                            md: 20,
                            lg: 20,
                            xl: 20,
                            xxl: 20,
                        }}
                    >
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <ImageUpload
                                name="employee_image"
                                callbackSetImageFile={setImage}
                                image={image}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Validate Name Required",
                                        whitespace: true,
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    { type: "email", message: "Email is not valid!" },
                                ]}
                                hasFeedback
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                name="contact"
                                label={"Phone Number (eg: +966 xxxxxxxx)"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Validate Number Required",
                                    },
                                ]}
                                hasFeedback
                            >
                                <PhoneInput
                                    localization={["sa"]}
                                    masks={{ sa: "(...) ..-..-.." }}
                                    placeholder="+966 (123) 45-67-89"
                                    country={"sa"}
                                    onlyCountries={["sa"]}
                                    style={{
                                        width: "100%",
                                        direction: "ltr",
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item name="dob" label="Date Of Birth" hasFeedback>
                                <DatePicker
                                    format="YYYY-MM-DD"
                                    style={{
                                        width: "100%",
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                name="department_id"
                                label="Department"
                                rules={[
                                    {
                                        required: true,
                                        message: "Validate Department Required",
                                    },
                                ]}
                                hasFeedback
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    options={employeeRawData.departments?.map((department) => {
                                        return {
                                            label: department.name,
                                            value: department.id,
                                        };
                                    })}
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                name="designation_id"
                                label="Designation"
                                rules={[
                                    {
                                        required: true,
                                        message: "Validate Department Required",
                                    },
                                ]}
                                hasFeedback
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    options={employeeRawData.designations?.map((designation) => {
                                        return {
                                            label: designation.name,
                                            value: designation.id,
                                        };
                                    })}
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item name="status" label="Status">
                                <Radio.Group buttonStyle="solid" key="status">
                                    <Radio.Button key="inactive" value="active">
                                        Active
                                    </Radio.Button>
                                    <Radio.Button key="active" value="inactive">
                                        Inactive
                                    </Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name="id" style={{ display: "none" }}>
                        <input type="hidden" />
                    </Form.Item>
                </Form>
                <Row justify="end" style={{ marginBottom: "20px" }}>
                    <Space size={20}>
                        <Button key="back" loading={loading} onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={handleAddEdit}
                        >
                            {drawerTitle === ADD_EMPLOYEE ? "Add Employee" : "Update"}
                        </Button>
                    </Space>
                </Row>
            </Drawer>
        </>
    );
};

export default Employees;
