import React, { useState, useEffect } from "react";
import {
    Card,
    Form,
    Input,
    Button,
    Table,
    Modal,
    Popconfirm,
    Radio,
    message,
    Select,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import NotificationWithIcon from "../../../../components/NotificationWithIcon";
import {
    DeleteTwoTone,
    EditTwoTone,
    LockOutlined,
    UnlockOutlined,
} from "@ant-design/icons";
import { addTag, deleteTag, getTags, updateTag } from "../../../../network/network";
import { debounce } from "lodash";

const Tags = () => {
    const ADD_TAGS = "Add Tag";
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [reloadTableData, setReloadTableData] = useState(false);
    const [tagAllData, setTagAllData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState('');
    const [modalTitle, setModalTitle] = useState(ADD_TAGS);
    const [tagsForm] = Form.useForm();

    const fetchData = () => {
        setIsDataLoading(true);
        getTags({
            page: currentPage,
            search: searchTerm,
            module_type: filter
        })
            .then((response) => {
                setTagAllData(response.data.data.tags);
                const paginationData = response.data.data.pagination;
                setPagination({
                    current: paginationData.current_page,
                    pageSize: paginationData.per_page,
                    total: paginationData.total,
                    showSizeChanger: false,
                });
            })
            .finally(() => {
                setIsDataLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    useEffect(() => {
        fetchData();
    }, [filter]);

    useEffect(() => {
        fetchData();
    }, [reloadTableData]);

    const debouncedFetchData = debounce(() => {
        fetchData();
    }, 500);

    useEffect(() => {
        debouncedFetchData();
        // Cleanup to cancel the debounced call if component unmounts or the value changes
        return () => debouncedFetchData.cancel();
    }, [searchTerm]);

    const showModal = () => {
        // Set Modal Title
        setModalTitle("Add Tag");
        // Show Modal
        setModalVisible(true);
    };

    const handleAPIResponse = (response, type) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
            setReloadTableData(!reloadTableData);
            // Success
            if (type === ADD_TAGS) {
                NotificationWithIcon(
                    "success",
                    "Tag Added",
                    "Tag added successfully!"
                );
            } else {
                NotificationWithIcon(
                    "success",
                    "Tag Updated",
                    "Tag updated successfully!"
                );
            }
        } else {
            // Error
            let errorMessages = Object.values(data.data.errors);
            errorMessages.forEach(function (key, value) {
                let temp = Object.values(key);
                message.error(temp[0]);
            });
        }
        // Reset Form Fields
        tagsForm.resetFields();
        // Hide Modal
        setModalVisible(false);
    };

    const handleAddEdit = () => {
        tagsForm
            .validateFields()
            .then((values) => {
                // Set Loading True
                setLoading(true);
                // Sending Request to API
                if (modalTitle === ADD_TAGS || modalTitle === "Add Tag") {
                    addTag(values)
                        .then((response) => handleAPIResponse(response, ADD_TAGS))
                        .catch((info) => {
                            console.log("API Failed:", info.response);
                        })
                        .finally(() => {
                            // Set Loading False
                            setLoading(false);
                        });
                } else {
                    updateTag(values)
                        .then((response) => handleAPIResponse(response, null))
                        .catch((info) => {
                            console.log("API Failed:", info.response);
                        })
                        .finally(() => {
                            // Set Loading False
                            setLoading(false);
                        });
                }
            })
            .catch((info) => {
                console.log("API Failed:", info);
            });
    };

    const handleCancel = () => {
        // Reset Form Fields
        tagsForm.resetFields();
        setModalVisible(false);
    };

    const handleEditModal = (record) => {
        tagsForm.resetFields();
        // Set Modal Title
        setModalTitle("Edit Tag : " + record.name);
        // Set Form Fields
        console.log(record);
        tagsForm.setFieldsValue({
            id: record.id,
            name: record.name,
            status: record.status,
        });
        // Show Modal
        setModalVisible(true);
    };

    const handleDelete = (id) => {
        deleteTag(id)
            .then((response) => {
                let data = response.data || response.response.data;
                if (data.success === true) {
                    // Success
                    setReloadTableData(!reloadTableData);
                    NotificationWithIcon("success", "Tag Deleted", data.message);
                } else {
                    // Error
                    NotificationWithIcon("error", "Something Went Wrong", data.message);
                }
            })
            .catch((info) => {
                // Error
                NotificationWithIcon(
                    "error",
                    "Something Went Wrong",
                    info.response.data.message
                );
            });
    };


    const handleDataTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
        setCurrentPage(pagination.current);
    };

    const tableColumns = [
        {
            title: "#",
            key: "index",
            width: "5%",
            render: (text, record, index) => {
                return ((pagination.current - 1) * pagination.pageSize) + index + 1;
            },
        },
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Module",
            align: "center",
            dataIndex: "module_type",
        },
        {
            title: "Status",
            align: "center",
            render: (record) => {
                return (
                    <>
                        {record.status === "Active" ? (
                            <UnlockOutlined
                                title="Active"
                                style={{ color: "green", fontSize: "18px" }}
                            />
                        ) : (
                            <LockOutlined
                                title="Inactive"
                                style={{ color: "red", fontSize: "18px" }}
                            />
                        )}
                    </>
                );
            },
        },
        {
            title: "Action",
            align: "center",
            width: "100px",
            render: (record) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            type="link"
                            onClick={() => {
                                handleEditModal(record);
                            }}
                            title="Edit record"
                            style={{
                                padding: 6,
                                fontSize: "18px",
                                borderRadius: "5px",
                                backgroundColor: "rgba(24, 144, 255, 0.2)",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <EditTwoTone twoToneColor="#1890ff" />
                        </Button>
                        <Popconfirm
                            title="Are you sure to delete this entity?"
                            onConfirm={() => handleDelete(record.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteTwoTone
                                style={{
                                    padding: 6,
                                    fontSize: "18px",
                                    borderRadius: "5px",
                                    backgroundColor: "rgba(255, 0, 0, 0.2)",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                twoToneColor="#ff0000"
                                title="Delete Entity"
                            />
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <Card style={{ marginBottom: "20px" }}>
                <PageHeader
                    style={{ padding: "0" }}
                    title="Tags"
                    ghost={false}
                    extra={[
                        <Button type="primary" onClick={showModal} key="add-tag">
                            + Add Tag
                        </Button>,
                    ]}
                />
            </Card>
            <Card>
                <Input
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    onPressEnter={() => fetchData()}
                    style={{ marginBottom: 20, width: '50%' }}
                />
                <Select
                    placeholder="Filter by Module"
                    allowClear
                    showSearch
                    style={{ width: 200, marginLeft: 20, marginBottom: 20 }}
                    onChange={(value) => {
                        setFilter(value || '');
                    }}
                >
                    <Select.Option value="employee">Employee</Select.Option>
                    <Select.Option value="fleet">Fleet</Select.Option>
                    <Select.Option value="customer">Customer</Select.Option>
                </Select>
                <Table
                    columns={tableColumns}
                    dataSource={tagAllData}
                    loading={isDataLoading}
                    rowKey="id"
                    pagination={pagination} onChange={handleDataTableChange}
                    bordered
                />
            </Card>
            {/* Add Edit Tag Modal */}
            <Modal
                open={modalVisible}
                title={modalTitle}
                onOk={handleAddEdit}
                onCancel={handleCancel}
                width={400}
                footer={[
                    <Button key="back" loading={loading} onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        loading={loading}
                        onClick={handleAddEdit}
                    >
                        {modalTitle === ADD_TAGS || modalTitle === "Add Tag"
                            ? "Add Tag"
                            : "update"}
                    </Button>,
                ]}
            >
                <Form
                    name="add-form"
                    onFinish={handleAddEdit}
                    layout="vertical"
                    style={{ width: "100%", margin: "0" }}
                    form={tagsForm}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[
                            {
                                required: true,
                                message: "Validate Tag Required",
                                whitespace: true,
                            },
                        ]}
                        hasFeedback
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name="module_type" label="Module Type">
                        <Radio.Group buttonStyle="solid" key="module_type">
                            <Radio.Button key="customer" value="customer">
                                Customer
                            </Radio.Button>
                            <Radio.Button key="employee" value="employee">
                                Employee
                            </Radio.Button>
                            <Radio.Button key="fleet" value="fleet">
                                Fleet
                            </Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    {!(modalTitle === ADD_TAGS || modalTitle === "Add Tag") && (
                        <Form.Item name="status" label="Status">
                            <Radio.Group buttonStyle="solid" key="status">
                                <Radio.Button key="inactive" value="Inactive">
                                    Inactive
                                </Radio.Button>
                                <Radio.Button key="active" value="Active">
                                    Active
                                </Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    )}
                    <Form.Item name="id" style={{ display: "none" }}>
                        <input type="hidden" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default Tags;
