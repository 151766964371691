import React, { useEffect, useState } from "react";
import { Card, Table, Tag } from "antd";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);


const DriverHistory = ({ drivers }) => {
    const [driverHistory, setDriverHistory] = useState([]);

    useEffect(() => {
        setDriverHistory(drivers);
    }, [drivers])

    const getStatusColor = (statusName) => {
        switch (statusName) {
            case 1:
                return 'green';
            default:
                return 'gray';
        }
    };

    const tableColumns = [
        {
            title: "#",
            key: "index",
            width: "5%",
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: "Driver Name",
            dataIndex: "name",
        },
        {
            title: 'Status',
            key: 'statusColor',
            width: '5px',
            render: (record) => {
                return (
                    <Tag bordered={false} color={getStatusColor(record.is_active)}>{record.is_active == 1 ? 'Active' : 'Inactive'}</Tag>
                );
            },
        },
        {
            title: "Active From",
            dataIndex: "from",
        },
        {
            title: "Active Till",
            dataIndex: "to",
        },
    ];

    return (
        <>
            <Card>
                <Table columns={tableColumns} dataSource={driverHistory} rowKey="id" bordered />
            </Card>
        </>
    );
};

export default DriverHistory;
