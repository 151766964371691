import React from 'react';
import { Button, Card, Divider, Tag, Typography } from 'antd';
import { HomeOutlined, IdcardOutlined, MailOutlined, PhoneOutlined, GlobalOutlined } from '@ant-design/icons';
import FleetProfile from './FleetProfile';
import NotificationsCard from './NotificationsCard';
import ContractsHistory from './ContractsHistory';
import FleetDetailsTable from './FleetDetailsTable';
import UpcomingMaintenance from './UpcomingMaintenance';
import TripInfoRow from './TripInfoRow';
import GoogleMapsCard from './GoogleMapsCard';

const { Text, Title } = Typography;

const GeneralInfo = ({ fleet, rawFleetData, cardStyle, notifications, contractsHistory, getStatusTag, fleetDetails, upcomingMaintenance, center }) => {

    return (
    <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <FleetDetailsTable fleet={fleet} rawFleetData={rawFleetData} cardStyle={cardStyle} />
                <UpcomingMaintenance maintenance={upcomingMaintenance} cardStyle={cardStyle} />
                <Card style={{ ...cardStyle, width: '30%' }}>
                    <TripInfoRow />
                    <GoogleMapsCard center={center} />
                </Card>
            </div>
        </>
  );
};

export default GeneralInfo;
