import React from 'react';
import { Row, Col, Typography, Badge, Button, Divider } from 'antd';
import { CalendarOutlined, CarOutlined, ClockCircleOutlined, EnvironmentOutlined } from '@ant-design/icons';

const { Text } = Typography;

const TripInfoRow = () => {
  return (
    <div style={{ background: '#f7f7f7', padding: '10px', borderRadius: '8px', margin: '10px 0' }}>
        <Row justify="space-between" align="middle" style={{ marginBottom: '12px' }}>
                        <Col>
                            <Badge status="success" text="Trip: Active" />
                        </Col>
                        <Col>
                            <Button type="primary" icon={<EnvironmentOutlined />} size="small" onClick={() => window.open('https://maps.google.com')}>
                                Maps
                            </Button>
                        </Col>
                    </Row>
                    <Divider style={{ margin: '10px 0' }} />
      <Row gutter={[16, 16]} justify="start">
        <Col span={12}>
          <div>
            <Text><CalendarOutlined style={{ color: '#1890ff', marginRight: '8px' }} />Start Date</Text>
            <div><Text strong>2023-01-01</Text></div>
          </div>
        </Col>
        <Col span={12}>
          <div>
            <Text><CalendarOutlined style={{ color: '#1890ff', marginRight: '8px' }} />End Date</Text>
            <div><Text strong>2023-01-07</Text></div>
          </div>
        </Col>
        <Col span={12}>
          <div>
            <Text><CarOutlined style={{ color: '#52c41a', marginRight: '8px' }} />Distance</Text>
            <div><Text strong>500km</Text></div>
          </div>
        </Col>
        <Col span={12}>
          <div>
            <Text><ClockCircleOutlined style={{ color: '#faad14', marginRight: '8px' }} />Duration</Text>
            <div><Text strong>6 days</Text></div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TripInfoRow;
