import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./pages/App/App";
import AppContextProvider from "./context/AppContextProvider";
import { BrowserRouter } from 'react-router-dom';
import "./i18n";
// import App from './App';
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <BrowserRouter>
        <AppContextProvider>
          <App />
        </AppContextProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
