import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Button,
  Table,
  Popconfirm,
  message,
  Row,
  Col,
  Space,
  DatePicker,
  Select,
  Drawer,
  Upload,
  Radio,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  addFleetDocument,
  deleteFleetDocument,
  updateFleetDocument,
  getFleetDocuments,
  rawDataFleet,
} from "../../../../network/network";
import NotificationWithIcon from "../../../../components/NotificationWithIcon";
import {
  DeleteTwoTone,
  EditTwoTone,
  LockOutlined,
  UnlockOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import moment from "moment";

const FleetDocument = () => {
  const ADD_FLEET_DOCUMENT = "Add Fleet Document";
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [fleetDocumentRawData, setFleetDocumentRawData] = useState([]);
  const [fleetDocumentData, setFleetDocumentData] = useState([]);
  const [drawerTitle, setDrawerTitle] = useState(ADD_FLEET_DOCUMENT);
  const [fleetDocumentsForm] = Form.useForm();

  useEffect(() => {
    rawDataFleet().then((response) => {
      setFleetDocumentRawData(response.data.data);
    });
  }, []);

  useEffect(() => {
    setIsDataLoading(true);
    getFleetDocuments()
      .then((response) => {
        setFleetDocumentData(response.data.data.fleetsDocument);
      })
      .finally(() => {
        setIsDataLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [reloadTableData]);

  const showDrawer = () => {
    setDrawerTitle(ADD_FLEET_DOCUMENT);
    setDrawerVisible(true);
  };

  const handleAPIResponse = (response, type) => {
    let data = response.data || response.response.data;
    if (data.success === true) {
      setReloadTableData(!reloadTableData);
      if (type === ADD_FLEET_DOCUMENT) {
        NotificationWithIcon(
          "success",
          "Fleet Document Added",
          "Fleet Document added successfully!"
        );
      } else {
        NotificationWithIcon(
          "success",
          "Fleet Document Updated",
          "Fleet Document updated successfully!"
        );
      }
    } else {
      let errorMessages = Object.values(data.data.errors);
      errorMessages.forEach(function (key, value) {
        let temp = Object.values(key);
        message.error(temp[0]);
      });
    }
    fleetDocumentsForm.resetFields();
    setDrawerVisible(false);
  };

  const handleAddEdit = () => {
    fleetDocumentsForm
      .validateFields()
      .then((values) => {
        setLoading(true);
        if (drawerTitle === ADD_FLEET_DOCUMENT) {
          addFleetDocument(values)
            .then((response) => {
              console.log(response, "response");
              handleAPIResponse(response, ADD_FLEET_DOCUMENT);
            })
            .catch((info) => {
              console.log("API Failed:", info.response);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          updateFleetDocument(values)
            .then((response) => handleAPIResponse(response, null))
            .catch((info) => {
              console.log("API Failed:", info.response);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      })
      .catch((info) => {
        console.log("API Failed:", info);
      });
  };

  const handleCancel = () => {
    fleetDocumentsForm.resetFields();
    setDrawerVisible(false);
  };

  const handleEditDrawer = (record) => {
    fleetDocumentsForm.resetFields();
    setDrawerTitle("Edit Fleet Document: " + record.fleet.name);
    fleetDocumentsForm.setFieldsValue({
      id: record.id,
      fleet_id: record.fleet.name,
      document_type_id: record.document_type.type,
      start_date: moment(record.start_date),
      end_date: moment(record.end_date),
      status_id: record.statuses.name,
      status: record.status,
    });
    setDrawerVisible(true);
  };

  const handleDelete = (id) => {
    deleteFleetDocument(id)
      .then((response) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
          setReloadTableData(!reloadTableData);
          NotificationWithIcon(
            "success",
            "Fleet Document Deleted",
            data.message
          );
        } else {
          NotificationWithIcon("error", "Something Went Wrong", data.message);
        }
      })
      .catch((info) => {
        NotificationWithIcon(
          "error",
          "Something Went Wrong",
          info.response.data.message
        );
      });
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const tableColumns = [
    {
      title: "#",
      key: "index",
      width: "5%",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: "Fleet",
      render: (record) => <>{record.fleet ? record.fleet.name : "-"}</>,
    },
    {
      title: "Document Type",
      render: (record) => (
        <>{record.document_type ? record.document_type.type : "-"}</>
      ),
    },
    {
      title: "Statuses",
      render: (record) => <>{record.statuses ? record.statuses.name : "-"}</>,
    },
    {
      title: "Start date",
      dataIndex: "start_date",
    },
    {
      title: "Status",
      align: "center",
      render: (record) => {
        return (
          <>
            {record.status === "Active" ? (
              <UnlockOutlined
                title="Active"
                style={{ color: "green", fontSize: "18px" }}
              />
            ) : (
              <LockOutlined
                title="Inactive"
                style={{ color: "red", fontSize: "18px" }}
              />
            )}
          </>
        );
      },
    },
    {
      title: "End Date",
      dataIndex: "end_date",
    },
    {
      title: "Action",
      align: "center",
      width: "100px",
      render: (record) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
            <Button
              type="link"
              onClick={() => {
                handleEditDrawer(record);
              }}
              title="Edit record"
              style={{
                padding: 6,
                fontSize: "18px",
                borderRadius: "5px",
                backgroundColor: "rgba(24, 144, 255, 0.2)",
                display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
              }}
            >
              <EditTwoTone twoToneColor="#1890ff" />
            </Button>
            <Popconfirm
              title="Are you sure to delete this entity?"
              onConfirm={() => handleDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteTwoTone
                style={{
                    padding: 6,
                    fontSize: "18px",
                    borderRadius: "5px",
                    backgroundColor: "rgba(255, 0, 0, 0.2)",
                    display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                  }}
                twoToneColor="#ff0000"
                title="Delete Entity"
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <PageHeader
          style={{ padding: "0" }}
          title="Fleet Documents"
          ghost={false}
          extra={[
            <Button
              type="primary"
              onClick={showDrawer}
              key="add-fleet-document"
            >
              + Add Fleet Documents
            </Button>,
          ]}
        />
      </Card>
      <Card>
        <Table
          columns={tableColumns}
          dataSource={fleetDocumentData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
          bordered
        />
      </Card>
      <Drawer
        title={drawerTitle}
        placement="right"
        onClose={handleCancel}
        open={drawerVisible}
        width={"calc(100% - 200px)"}
      >
        <Form
          name="add-form"
          onFinish={handleAddEdit}
          layout="vertical"
          style={{ width: "100%", margin: "0" }}
          form={fleetDocumentsForm}
        >
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 20,
              lg: 20,
              xl: 20,
              xxl: 20,
            }}
          >
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item name="attachment" label="Attachment">
                <Upload {...props}>
                  <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="fleet_id"
                label="Fleet"
                rules={[
                  {
                    required: true,
                    message: "Validate Fleet Required",
                  },
                ]}
                hasFeedback
              >
                <Select
                  showSearch
                  allowClear
                  options={fleetDocumentRawData.fleets?.map((fleet) => {
                    return {
                      label: fleet.name,
                      value: fleet.id,
                    };
                  })}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="document_type_id"
                label="Document Type"
                rules={[
                  {
                    required: true,
                    message: "Validate Document Type Required",
                  },
                ]}
                hasFeedback
              >
                <Select
                  showSearch
                  allowClear
                  options={fleetDocumentRawData.documents_types?.map(
                    (document) => {
                      return {
                        label: document.type,
                        value: document.id,
                      };
                    }
                  )}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="status_id"
                label="Statuses"
                rules={[
                  {
                    required: true,
                    message: "Validate Status Required",
                  },
                ]}
                hasFeedback
              >
                <Select
                  showSearch
                  allowClear
                  options={fleetDocumentRawData.statuses?.map((status) => {
                    return {
                      label: status.name,
                      value: status.id,
                    };
                  })}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="start_date"
                label="Start Date"
                rules={[
                  {
                    required: true,
                    message: "Validate Date Required",
                  },
                ]}
                hasFeedback
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="end_date"
                label="End Date"
                rules={[
                  {
                    required: true,
                    message: "Validate Date Required",
                  },
                ]}
                hasFeedback
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          {!(
            drawerTitle === ADD_FLEET_DOCUMENT ||
            drawerTitle === "Add Fleet Document"
          ) && (
            <Form.Item name="status" label="Status">
              <Radio.Group buttonStyle="solid" key="status">
                <Radio.Button key="inactive" value="Inactive">
                  Inactive
                </Radio.Button>
                <Radio.Button key="active" value="Active">
                  Active
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          )}
          <Form.Item name="id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
        <Row justify="end" style={{ marginBottom: "20px" }}>
          <Space size={20}>
            <Button key="back" loading={loading} onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleAddEdit}
            >
              {drawerTitle === ADD_FLEET_DOCUMENT
                ? "Add Fleet Document"
                : "Update"}
            </Button>
          </Space>
        </Row>
      </Drawer>
    </>
  );
};

export default FleetDocument;
