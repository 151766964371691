import React, { useEffect, useState } from "react";
import { Card, Tabs } from "antd";
import { useParams } from "react-router-dom";
import { ContactsOutlined, FileFilled, HomeOutlined, InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import EmployeeProfile from "./components/EmployeeProfile";
import GeneralInfo from "./components/GeneralInfo";
import CustomBreadcrumb from "../../../../../components/CustomBreadcrumb";
import Mate from "../../../../../components/Mate/Mate";
import Documents from "../../../../../components/Documents";
import { rawDataEmployee, showEmployee } from "../../../../../network/network";
import Contacts from "../../../../../components/Contacts";

const EmployeeDetails = () => {
    let { id } = useParams();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [employee, setEmployee] = useState({});
    const [activeTabKey, setActiveTabKey] = useState('1');
    const [employeeRawData, setEmployeeRawData] = useState([]);

    useEffect(() => {
        rawDataEmployee().then((response) => {
            setEmployeeRawData(response.data.data);
        });
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setIsDataLoading(true);
            try {
                const response = await showEmployee(id);
                setEmployee(response.data.data);
                console.log(response)
            } catch (error) {
                console.log(error);
            } finally {
                setIsDataLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const handleTabChange = (key) => {
        setActiveTabKey(key);
    };

    const commonCardStyles = {
        // boxShadow: '4px 4px 8px rgba(0,0,0,0.1)',
        // borderRadius: '8px',
        marginBottom: '20px',
        padding: '5px',
    };

    const tabItems = [
        {
            label: <><InfoCircleOutlined /> General Info</>,
            key: '1',
            children: <GeneralInfo employee={employee} cardStyle={commonCardStyles} employeeRawData={employeeRawData} />,
        },
        {
            label: <><ContactsOutlined /> Contacts</>,
            key: '4',
            children: <Contacts entityId={id} entityType={'Employee'} />,
        },
        {
            label: <><FileFilled /> Documents</>,
            key: '5',
            children: <Documents entityType={'Employee'} entityId={id} isExpiryDate={true} />,
        },
        {
            label: '👋 Mate',
            key: '6',
            children: <Mate entityType="Employee" entityId={id} />,
        },
    ];


    const breadcrumbRoutes = [
        {
            path: "/",
            breadcrumbName: "Home",
            icon: <HomeOutlined />,
        },
        {
            path: "/employees",
            breadcrumbName: "Employees",
            icon: <UserOutlined />,
        },
        {
            breadcrumbName: "Employee Details",
            icon: <FileFilled />,
        },
    ];

    return (
        <div>
            <CustomBreadcrumb items={breadcrumbRoutes} />
            <h1>Employee Details</h1>
            <EmployeeProfile employee={employee} cardStyle={commonCardStyles} tags={employeeRawData?.tags} />
            <Card>
                <Tabs items={tabItems} defaultActiveKey="1" onChange={handleTabChange} />
            </Card>
        </div>
    );
};

export default EmployeeDetails;
