import React, { useContext, useEffect, useState } from 'react';
import { Timeline, Button, Card, Popconfirm, Tag, Checkbox } from 'antd';
import { activities } from '../../network/network';
import { CalendarOutlined, CheckCircleTwoTone, ClockCircleOutlined, DeleteOutlined, DeleteTwoTone, EditOutlined, PlusCircleOutlined, UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from "dayjs/plugin/relativeTime";
import { AppContext } from "../../context/AppContextProvider";
dayjs.extend(relativeTime);
dayjs.extend(utc);

const Activities = ({ entityType, entityId, refresh, onEditActivity, onDeleteActivity, handleUpdateActivityStatus }) => {
    const { authUser } = useContext(AppContext);
    const [activitiesData, setActivitiesData] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        fetchActivities();
    }, [page, refresh]);

    const fetchActivities = async () => {
        setLoading(true);
        try {
            const response = await activities(entityType, entityId, page);
            if (response.data && response.data.data.activities.length > 0) {
                setActivitiesData(response.data.data.activities);
                setHasMore(response.data.data.pagination.total_pages > response.data.data.pagination.current_page);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Failed to fetch activities:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    const activityTimelineItem = (activity) => {
        return {
            label: <span style={{
                fontSize: '14px', fontWeight: 'bold', color: '#555', borderLeft: activity.status === 'completed' ? '3px solid #52c41a' : '3px solid #faad14',
                background: activity.status === 'completed' ? '#f6ffed' : '#fffbe6',
                padding: '10px',
                borderRadius: '5px',
            }}>{dayjs(activity.created_at).fromNow()}</span>,
            dot: <CalendarOutlined style={{ color: activity.status === 'completed' ? '#52c41a' : '#faad14' }} />,
            children: (
                <div style={{
                    borderLeft: activity.status === 'completed' ? '3px solid #52c41a' : '3px solid #faad14',
                    background: activity.status === 'completed' ? '#f6ffed' : '#fffbe6',
                    padding: '10px',
                    borderRadius: '5px',
                    marginBottom: '10px'
                }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h4 style={{ margin: '0px', color: '#1890ff' }}>{activity.title}</h4>
                        {activity.created_by.id === authUser.id && (
                            <div>
                                <Checkbox
                                    checked={activity.status === 'completed'}
                                    onChange={() => handleUpdateActivityStatus({...activity, status: activity.status === 'completed' ? 'pending' : 'completed' })}
                                ></Checkbox>
                                <Button
                                    type="link"
                                    icon={<EditOutlined />}
                                    onClick={() => onEditActivity(activity)}
                                >
                                    Edit
                                </Button>
                                <Popconfirm
                                    title="Are you sure to delete this entity? All related data will be deleted."
                                    onConfirm={() => onDeleteActivity(activity.id)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <DeleteTwoTone style={{ padding: 6, fontSize: "18px" }}
                                        twoToneColor="#ff0000"
                                        title="Delete Entity"
                                    >
                                    </DeleteTwoTone>
                                </Popconfirm>
                            </div>
                        )}
                    </div>
                    <p style={{ fontSize: '14px', color: '#666', marginBottom: '5px' }}>
                        <UserOutlined /> {activity.created_by?.name} added an activity.
                    </p>
                    <div>
                        <p style={{ background: '#fff', margin: '5px 0', padding: '5px 10px', borderRadius: '5px' }}>
                            {activity.description}
                        </p>
                        <p style={{ fontSize: '14px', color: '#666' }}>
                            {activity.assigned_to ? (
                                <>
                                    <span style={{ fontWeight: 'bold' }}>Assigned Employee:</span> {activity.assigned_to.name}
                                </>
                            ) : ''}
                            <br />
                            <span style={{ fontWeight: 'bold' }}>Reminder:</span> {activity.reminder ? 'ON' : 'OFF'}
                        </p>
                    </div>
                </div>
            )
        };
    };

    return (
        <>
            <div style={{ textAlign: 'left' }}>
                <h2 style={{ borderBottom: '2px solid #eee', paddingBottom: '10px' }}>Activities</h2>
            </div>
            <Timeline style={{ width: '100%' }} mode="left" items={activitiesData.map(activity => (
                activityTimelineItem(activity)
            ))} />
            {hasMore && (
                <div style={{ textAlign: 'center' }}>
                    <Button onClick={handleLoadMore} loading={loading}>
                        Load More
                    </Button>
                </div>
            )}
        </>
    );
};

export default Activities;
