import React, { useEffect, useState } from 'react';
import { Timeline, Button, Card, Tabs, Form, Modal, Input, DatePicker, Checkbox, Select, message } from 'antd';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from "dayjs/plugin/relativeTime";
import Logs from './Logs';
import Activities from './Activities';
import { deleteActivity, deleteNote, getEmployees, storeActivity, storeNote, updateActivity, updateNote } from '../../network/network';
import NotificationWithIcon from '../NotificationWithIcon';
import Notes from './Notes';
dayjs.extend(relativeTime);
dayjs.extend(utc);

const Mate = ({ entityType, entityId }) => {
    const [showActivityModal, setShowActivityModal] = useState(false);
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [selectedNote, setSelectedNote] = useState(null);
    const [refreshActivities, setRefreshActivities] = useState(false);
    const [refreshNotes, setRefreshNotes] = useState(false);
    const [refreshLogs, setRefreshLogs] = useState(false);
    const [users, setUsers] = useState([]);
    const [activityForm] = Form.useForm();
    const [noteForm] = Form.useForm();

    useEffect(() => {
        getEmployees().then(response => {
            setUsers(response.data.data.employees);
        });
    }, []);

    const onEditActivity = (activity) => {
        setSelectedActivity(activity);
        activityForm.setFieldsValue({
            ...activity,
            start_date: dayjs(activity.start_date, "YYYY-MM-DD"),
            end_date: dayjs(activity.end_date, "YYYY-MM-DD"),
            assigned_to: activity.assigned_to?.id,
        });
        setShowActivityModal(true);
    };

    const onEditNote = (note) => {
        setSelectedNote(note);
        noteForm.setFieldsValue(note);
        setShowNoteModal(true);
    };

    const showAddActivityModal = () => {
        setSelectedActivity(null);
        activityForm.resetFields();
        setShowActivityModal(true);
    };

    const showAddNoteModal = () => {
        setSelectedNote(null);
        noteForm.resetFields();
        setShowNoteModal(true);
    };

    const handleAddActivity = (values) => {
        const data = {
            ...values,
            start_date: dayjs.utc(values.start_date).local().format('YYYY-MM-DD'),
            end_date: dayjs.utc(values.end_date).local().format('YYYY-MM-DD')
        };
        if (selectedActivity) {
            data.id = selectedActivity.id;
        }
        let apiCall = selectedActivity ? updateActivity : storeActivity;
        apiCall(data).then((response) => {
            NotificationWithIcon("success", selectedActivity ? 'Activity updated successfully' : 'Activity added successfully');
            setShowActivityModal(false);
            activityForm.resetFields();
            setSelectedActivity(null);
            setRefreshActivities(!refreshActivities);
            setRefreshLogs(!refreshLogs);
        }).catch(error => {
            NotificationWithIcon("error", 'Failed to add/update activity');
        });
    };

    const handleUpdateActivityStatus = (values) => {
        let data = {
            ...values,
            start_date: dayjs(values.start_date, "YYYY-MM-DD"),
            end_date: dayjs(values.end_date, "YYYY-MM-DD"),
            assigned_to: values.assigned_to?.id,
            subject_id: entityId,
            subject_type: entityType,
        }

        updateActivity(data).then((response) => {
            NotificationWithIcon("success", 'Activity status changed successfully');
            setRefreshActivities(!refreshActivities);
            setRefreshLogs(!refreshLogs);
        }).catch(error => {
            NotificationWithIcon("error", 'Failed to update activity');
        });
    };

    const onDeleteActivity = (activityId) => {
        deleteActivity(activityId)
            .then((response) => {
                let data = response.data || response.response.data;
                if (data.success === true) {
                    NotificationWithIcon("success", "Activity Deleted", data.message);
                    setRefreshActivities(!refreshActivities);
                    setRefreshLogs(!refreshLogs);
                } else {
                    // Error
                    NotificationWithIcon("error", "Something Went Wrong", data.message);
                }
            })
            .catch((info) => {
                // Error
                NotificationWithIcon(
                    "error",
                    "Something Went Wrong",
                    info.response.data.message
                );
            });
    };

    const handleAddNote = (values) => {
        if (selectedNote) {
            values.id = selectedNote.id;
        }
        let apiCall = selectedNote ? updateNote : storeNote;
        console.log(selectedNote ? 'updating...': 'adding...')
        apiCall(values).then((response) => {
            NotificationWithIcon("success", selectedNote ? 'Note updated successfully' : 'Note added successfully');
            setShowNoteModal(false);
            noteForm.resetFields();
            setSelectedNote(null);
            setRefreshNotes(!refreshNotes);
            setRefreshLogs(!refreshLogs);
        }).catch(error => {
            NotificationWithIcon("error", 'Failed to add/update note');
        });
    };

    const onDeleteNote = (noteId) => {
        deleteNote(noteId)
            .then((response) => {
                let data = response.data || response.response.data;
                if (data.success === true) {
                    NotificationWithIcon("success", "Note Deleted", data.message);
                    setRefreshNotes(!refreshNotes);
                    setRefreshLogs(!refreshLogs);
                } else {
                    // Error
                    NotificationWithIcon("error", "Something Went Wrong", data.message);
                }
            })
            .catch((info) => {
                // Error
                NotificationWithIcon(
                    "error",
                    "Something Went Wrong",
                    info.response.data.message
                );
            });
    };

    const tabItems = [
        {
            label: 'Logs',
            key: '1',
            children: <Logs entityType={entityType} entityId={entityId} refresh={refreshLogs} onEditActivity={onEditActivity} onDeleteActivity={onDeleteActivity} onEditNote={onEditNote} onDeleteNote={onDeleteNote} handleUpdateActivityStatus={handleUpdateActivityStatus} />,
        },
        {
            label: 'Activities',
            key: '2',
            children: <Activities entityType={entityType} entityId={entityId} refresh={refreshActivities} onEditActivity={onEditActivity} onDeleteActivity={onDeleteActivity} handleUpdateActivityStatus={handleUpdateActivityStatus} />,
        },
        {
            label: 'Notes',
            key: '3',
            children: <Notes entityType={entityType} entityId={entityId} refresh={refreshNotes} onEditNote={onEditNote} onDeleteNote={onDeleteNote} />,
        },
    ];

    return (
        <div>
            <Tabs
                items={tabItems}
                defaultActiveKey="1"
                tabBarExtraContent={{
                    right: (
                        <>
                            <Button
                                onClick={showAddActivityModal}
                                style={{
                                    backgroundColor: '#1890ff', // Primary color
                                    color: 'white',
                                    borderRadius: '5px',
                                    marginRight: '8px',
                                    fontWeight: '500'
                                }}
                                icon={<PlusCircleOutlined />}
                            >
                                Add Activity
                            </Button>
                            <Button
                                onClick={showAddNoteModal}
                                style={{
                                    backgroundColor: 'transparent',
                                    color: '#1890ff', // Primary color text for default button
                                    border: '1px solid #d9d9d9',
                                    borderRadius: '5px',
                                    fontWeight: '500'
                                }}
                                icon={<EditOutlined />}
                            >
                                Add Note
                            </Button>
                        </>
                    )
                }}
            />
            {/* Activity Modal */}
            <Modal
                title={selectedActivity ? "Edit Activity" : "Add Activity"}
                open={showActivityModal}
                onOk={() => activityForm.submit()}
                onCancel={() => {
                    setShowActivityModal(false);
                    setSelectedActivity(null);
                    activityForm.resetFields();
                }}
            >
                <Form form={activityForm} onFinish={handleAddActivity}>
                    <Form.Item name="title" label="Title" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="description" label="Description">
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item name="start_date" label="Start Date" rules={[{ required: true }]}>
                        <DatePicker format="YYYY-MM-DD" />
                    </Form.Item>
                    <Form.Item name="end_date" label="End Date" rules={[{ required: true }]}>
                        <DatePicker format="YYYY-MM-DD" />
                    </Form.Item>
                    <Form.Item name="reminder" valuePropName="checked">
                        <Checkbox>Reminder</Checkbox>
                    </Form.Item>
                    <Form.Item name="assigned_to" label="Assigned To" rules={[{ required: true }]}>
                        <Select options={users.map(user => ({ label: user.name, value: user.id }))} />
                    </Form.Item>
                    <Form.Item name="status" label="Status" rules={[{ required: true }]}>
                        <Select options={[{ label: 'Pending', value: 'pending' }, { label: 'Completed', value: 'completed' }]} />
                    </Form.Item>
                    <Form.Item name="subject_type" hidden initialValue={entityType}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="subject_id" hidden initialValue={entityId}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>

            {/* Note Modal */}
            <Modal
                title={selectedNote ? "Edit Note" : "Add Note"}
                open={showNoteModal}
                onOk={() => noteForm.submit()}
                onCancel={() => {
                    setShowNoteModal(false);
                    setSelectedNote(null);
                    noteForm.resetFields();
                }}
            >
                <Form form={noteForm} onFinish={handleAddNote}>
                    <Form.Item name="description" label="Description">
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item name="subject_type" hidden initialValue={entityType}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="subject_id" hidden initialValue={entityId}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default Mate;
