import React from 'react';
import { Card, List, Badge } from 'antd';

const UpcomingMaintenance = ({ maintenance, cardStyle }) => {
  return (
    <div style={{ width: '30%', ...cardStyle }}>
      <List
        header={<div style={{ fontWeight: 'bold' }}>Upcoming Maintenance</div>}
        dataSource={maintenance}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              avatar={item.icon}
              title={item.title}
              description={`${item.date}`}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default UpcomingMaintenance;
