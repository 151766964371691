import Login from "./pages/Auth/Login/Login";

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "SAR",
});

export const findPercentage = (discount, amount) => {
  return (discount / 100) * amount;
};

export const filterPagesByAddons = (ContentPages, authUser) => {
  if (authUser) {
    return ContentPages.filter((page) => {
      if (
        [
          "home",
          "configuration",
          "services",
          "categories",
          "currencies",
          "loadTypes",
          "location",
          "employeeConfiguration",
          "departments",
          "designation",
          "tags",
          "documentType",
          "employees",
          "fleetConfiguration",
          "status",
          "documentTypes",
          "fleetType",
          "hubs",
          "fleetMakes",
          "fleets",
          "fleetDocument",
          "customerManagement",
          "customerConfiguration",
          "customers",
          "customerContracts",
          "jobOrders",
          "singleTrips",
          "dispatchManagement",
          "dispatchOrders",
        ].includes(page.name)
      ) {
        return true;
      }
      return false;
    });
  } else {
    return [
      {
        path: "/login",
        name: "login",
        text: "login",
        component: Login,
        icon: false,
        showInSider: false,
      },
      {
        path: "*",
        name: "login",
        text: "login",
        component: Login,
        icon: false,
        showInSider: false,
      },
    ];
  }
};
