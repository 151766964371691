import React, { useState, useEffect } from "react";
import { Card, Button, Table, Input, Form, Row, Col, Popconfirm, Modal } from "antd";
import { DeleteTwoTone, EditFilled, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { debounce } from "lodash";
import { PageHeader } from "@ant-design/pro-layout";
import PhoneInput from "react-phone-input-2";
import { addMultipleContacts, deleteContact, getContacts, updateContact } from "../network/network";
import NotificationWithIcon from "./NotificationWithIcon";
dayjs.extend(utc);


const Contacts = ({ entityType, entityId }) => {
    const [loading, setLoading] = useState(false);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [contactsData, setContactsData] = useState([]);
    const [selectedContact, setSelectedContact] = useState({ contact_person: null, job_role: null, email: null, phone: [null] });
    const [pagination, setPagination] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [form] = Form.useForm();
    const [editForm] = Form.useForm();

    const [contacts, setContacts] = useState([{ contact_person: null, job_role: null, email: null, phone: [null] }]);

    const addContact = () => {
        setContacts([...contacts, { contact_person: null, job_role: null, email: null, phone: [null] }]);
    };

    const removeContact = (index) => {
        const updatedContacts = [...contacts];
        updatedContacts.splice(index, 1);
        setContacts(updatedContacts);
    };

    const addPhone = (contactIndex) => {
        const updatedContacts = [...contacts];
        updatedContacts[contactIndex].phone.push('');
        setContacts(updatedContacts);
    };

    const removePhone = (contactIndex, phoneIndex) => {
        const updatedContacts = [...contacts];
        updatedContacts[contactIndex].phone.splice(phoneIndex, 1);
        setContacts(updatedContacts);
    };

    const handlePhoneChange = (contactIndex, phoneIndex, value) => {
        const updatedContacts = [...contacts];
        updatedContacts[contactIndex].phone[phoneIndex] = value;
        setContacts(updatedContacts);
    };

    const fetchData = async () => {
        try {
            console.log(entityId, entityType)
            const contactsRespone = await getContacts({
                page: currentPage,
                search: searchTerm,
            }, entityType, entityId);
            setContactsData(contactsRespone.data.data.contacts);
            const paginationData = contactsRespone.data.data.pagination;
            setPagination({
                current: paginationData.current_page,
                pageSize: paginationData.per_page,
                total: paginationData.total,
                showSizeChanger: false,
            });
        } catch (error) {
            console.error("Fetching data failed:", error);
        } finally {
            setIsDataLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    const debouncedFetchData = debounce(() => {
        fetchData();
    }, 500);

    useEffect(() => {
        debouncedFetchData();
        // Cleanup to cancel the debounced call if component unmounts or the value changes
        return () => debouncedFetchData.cancel();
    }, [searchTerm]);


    const showForm = (shouldShow) => {
        setIsFormVisible(shouldShow);
    };


    const handleAPIResponse = (response, action) => {
        if (response.success === true) {
            NotificationWithIcon("success", `Customer Contact(s) ${action} successfully`, `Customer Contact(s) ${action} Successfully`);
            fetchData();
        } else {
            response.data.errors.forEach(function (key, value) {
                let temp = Object.values(key);
                NotificationWithIcon("error", "Something Went Wrong", temp[0]);
            });
        }
    };

    const handleAdd = async () => {
        try {
            setLoading(true);
            const values = await form.validateFields();
            console.log('values: ', values);
            const response = await addMultipleContacts(values, entityType, entityId);
            handleAPIResponse(response.data, 'added');
            setContacts([{ contact_person: null, job_role: null, email: null, phone: [null] }]);
            form.resetFields();
            setIsFormVisible(false);
        } catch (error) {
            console.error("Validation or API call failed:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = async () => {
        try {
            setLoading(true);
            const values = await editForm.validateFields();
            const response = await updateContact(values, values.id);
            handleAPIResponse(response.data, 'updated');
            setSelectedContact({ contact_person: null, job_role: null, email: null, phone: [null] });
            editForm.resetFields();
            setModalVisible(false);
        } catch (error) {
            console.error("Validation or API call failed:", error);
        } finally {
            setLoading(false);
        }
    };


    const handleDelete = (contactId) => {
        deleteContact(contactId)
            .then((response) => {
                let data = response.data || response.response.data;
                if (data.success === true) {
                    NotificationWithIcon("success", "Contact Deleted", data.message);
                    fetchData();
                } else {
                    // Error
                    NotificationWithIcon("error", "Something Went Wrong", data.message);
                }
            })
            .catch((info) => {
                // Error
                NotificationWithIcon(
                    "error",
                    "Something Went Wrong",
                    info.response.data.message
                );
            });
    };

    const handleContactsTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
        setCurrentPage(pagination.current);
    };

    const handleCancel = () => {
        setSelectedContact({ contact_person: null, job_role: null, email: null, phone: [null] });
        editForm.resetFields();
        setModalVisible(false);
    };

    const handleEditModal = (record) => {
        editForm.resetFields();
        editForm.setFieldsValue({
            id: record.id,
            contact_person: record.contact_person,
            job_role: record.job_role,
            email: record.email,
            phone: record.phone,
        });
        setSelectedContact(record);
        setModalVisible(true);
    };

    const tableColumns = [
        {
            title: "#",
            key: "index",
            width: "5%",
            render: (text, record, index) => {
                return ((pagination.current - 1) * pagination.pageSize) + index + 1;
            },
        },
        {
            title: "Contact Person",
            dataIndex: "contact_person",
        },
        {
            title: "Designation",
            dataIndex: "job_role",
        },
        {
            title: "Email",
            dataIndex: "email",
        },
        {
            title: "phone",
            render: (text, record, index) => {
                return record.phone.join(', ');
            },
        },
        {
            title: "Action",
            align: "center",
            width: "100px",
            render: (record) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            type="link"
                            onClick={() => {
                                handleEditModal(record);
                            }}
                            title="Edit Contact"
                            style={{
                                padding: 6,
                                fontSize: "18px",
                                borderRadius: "5px",
                                backgroundColor: "rgba(82, 196, 26, 0.2)",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <EditFilled style={{ color: "#52c41a" }} />
                        </Button>
                        <Popconfirm
                            title="Are you sure to delete this entity?"
                            onConfirm={() => handleDelete(record.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteTwoTone
                                style={{
                                    padding: 6,
                                    fontSize: "18px",
                                    borderRadius: "5px",
                                    backgroundColor: "rgba(255, 0, 0, 0.2)",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                twoToneColor="#ff0000"
                                title="Delete Entity"
                            />
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <Card style={{ marginBottom: "20px" }}>
                <PageHeader
                    style={{ padding: "0" }}
                    title={`Contacts`}
                    ghost={false}
                    extra={[
                        <div key={"btns"}>
                            <Button style={{ display: isFormVisible ? 'none' : 'block' }} type="primary" onClick={() => { showForm(true) }} key="add-customer-contacts">
                                + Add Contacts
                            </Button>
                            <Button style={{ display: isFormVisible ? 'block' : 'none' }} type="primary" onClick={() => { showForm(false) }} key="cancel-customer-contacts">
                                Cancel
                            </Button>
                        </div>
                    ]}
                />
                <div style={{ display: isFormVisible ? 'block' : 'none' }}>
                    <Form form={form} onFinish={handleAdd} layout="vertical">
                        {contacts.map((contact, index) => (
                            <Row key={index} gutter={16} style={{ marginBottom: 8 }}>
                                <Col span={6}>
                                    <Form.Item name={['contacts', index, 'contact_person']} label="Contact Person" rules={[{ required: true, message: 'Please input the contact person' }]}>
                                        <Input placeholder="Enter Contact Person" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item name={['contacts', index, 'job_role']} label="Designation" rules={[{ required: true, message: 'Please input the Designation' }]}>
                                        <Input placeholder="Enter Designation" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item name={['contacts', index, 'email']} label="Email" rules={[{ required: true, message: 'Please input the email' }]}>
                                        <Input type="email" placeholder="Enter Email" />
                                    </Form.Item>
                                </Col>
                                <Col span={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {index > 0 && (
                                        <Button type="link" danger onClick={() => removeContact(index)} icon={<MinusCircleOutlined />}>
                                            Remove Contact
                                        </Button>
                                    )}
                                </Col>
                                <Col span={24}>
                                    <Row gutter={16}>
                                        {contact.phone.map((phone, phoneIndex) => (
                                            <Col span={phoneIndex > 0 ? 9 : 6} key={phoneIndex} style={{ marginBottom: 8, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <Form.Item name={['contacts', index, 'phone', phoneIndex]} label="Phone" rules={[{ required: true, message: 'Please input the Phone' }]}>
                                                    {/* <Input type="tel" placeholder="Enter Phone" value={phone} onChange={(e) => handlePhoneChange(index, phoneIndex, e.target.value)} /> */}
                                                    <PhoneInput
                                                        localization={["sa"]}
                                                        country={"sa"}
                                                        //   onlyCountries={["sa"]}
                                                        masks={{ sa: "(...) ..-..-.." }}
                                                        placeholder="+966 (123) 45-67-89"
                                                        style={{
                                                            width: "20px",
                                                            direction: "ltr",
                                                        }}
                                                    />
                                                </Form.Item>
                                                {phoneIndex > 0 && (
                                                    <Button type="link" danger onClick={() => removePhone(index, phoneIndex)} icon={<MinusCircleOutlined />}>
                                                        Remove
                                                    </Button>
                                                )}
                                            </Col>
                                        ))}
                                        <Col span={4} style={{ marginBottom: 8, display: 'flex', alignItems: 'center' }}>
                                            <Button type="dashed" onClick={() => addPhone(index)} block icon={<PlusOutlined />}>
                                                Add Phone
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={addContact} block icon={<PlusOutlined />}>
                                Add Contact
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Save Contacts
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Card>
            <Card>
                <Input
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    onPressEnter={() => fetchData()}
                    style={{ marginBottom: 20, width: '50%' }}
                />
                <Table columns={tableColumns} dataSource={contactsData} loading={isDataLoading} rowKey="id" pagination={pagination} onChange={handleContactsTableChange} bordered />
            </Card>
            {/* Edit Modal */}
            <Modal
                open={modalVisible}
                title="Edit Contact"
                onOk={handleEdit}
                onCancel={handleCancel}
                width={700}
                footer={[
                    <Button key="back" loading={loading} onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        loading={loading}
                        onClick={handleEdit}
                    >
                        Update Contact
                    </Button>,
                ]}
            >
                <Form
                    name="edit-form"
                    onFinish={handleEdit}
                    layout="vertical"
                    style={{ width: "100%", margin: "0" }}
                    form={editForm}
                >
                    <Row gutter={16} style={{ marginBottom: 8 }}>
                        <Col span={8}>
                            <Form.Item name={'contact_person'} label="Contact Person" rules={[{ required: true, message: 'Please input the contact person' }]}>
                                <Input placeholder="Enter Contact Person" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'job_role'} label="Designation" rules={[{ required: true, message: 'Please input the Designation' }]}>
                                <Input placeholder="Enter Designation" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'email'} label="Email" rules={[{ required: true, message: 'Please input the email' }]}>
                                <Input type="email" placeholder="Enter Email" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Row gutter={16}>
                                {selectedContact.phone.map((phone, phoneIndex) => (
                                    <Col span={phoneIndex > 0 ? 12 : 10} key={phoneIndex} style={{ marginBottom: 8, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Form.Item name={['phone', phoneIndex]} label="Phone" rules={[{ required: true, message: 'Please input the Phone' }]}>
                                            {/* <Input type="tel" placeholder="Enter Phone" value={phone} /> */}
                                            <PhoneInput
                                                        localization={["sa"]}
                                                        country={"sa"}
                                                        //   onlyCountries={["sa"]}
                                                        masks={{ sa: "(...) ..-..-.." }}
                                                        placeholder="+966 (123) 45-67-89"
                                                        style={{
                                                            width: "20px",
                                                            direction: "ltr",
                                                        }}
                                                    />
                                        </Form.Item>
                                        {phoneIndex > 0 && (
                                            <Button type="link" danger onClick={() => {
                                                const updatedContact = { ...selectedContact };
                                                updatedContact.phone.splice(phoneIndex, 1);
                                                setSelectedContact(updatedContact);
                                            }} icon={<MinusCircleOutlined />}>
                                                Remove
                                            </Button>
                                        )}
                                    </Col>
                                ))}
                                <Col span={6} style={{ marginBottom: 8, display: 'flex', alignItems: 'center' }}>
                                    <Button type="dashed" onClick={() => setSelectedContact({ ...selectedContact, 'phone': [...selectedContact.phone, ''] })} block icon={<PlusOutlined />}>
                                        Add Phone
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Form.Item name="id" style={{ display: "none" }}>
                        <input type="hidden" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default Contacts;
