import React, { useEffect, useState } from 'react';
import { Button, Card, Divider, Tag, Typography, Input, Select, Form } from 'antd';
import { CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined, UserOutlined } from '@ant-design/icons';
import { updateEmployee } from '../../../../../../network/network';
import NotificationWithIcon from '../../../../../../components/NotificationWithIcon';
import ImageUpload from '../../../../../../components/ImageUpload';
const { Text } = Typography;
const { Option } = Select;

const EmployeeProfile = ({ employee, cardStyle }) => {
    const [editForm] = Form.useForm();
    const [editMode, setEditMode] = useState(false);
    const [image, setImage] = useState(false);
    const [employeeData, setEmployeeData] = useState({
        ...employee,
    });

    useEffect(() => {
        setEmployeeData({
            ...employee,
        });
    }, [employee])

    const handleEdit = () => {
        setEditMode(true);
        setImage(employeeData.image);
        editForm.setFieldsValue({
            id: employeeData.id,
            name: employeeData.name,
            email: employeeData.email,
            status: employeeData.status,
        });
    };

    const handleCancel = () => {
        editForm.resetFields();
        setEditMode(false);
    };


    const handleAPIResponse = (response) => {
        if (response.success === true) {
            NotificationWithIcon("success", `Employee updated successfully`, `Employee updated Successfully`);
            editForm.resetFields();
            console.log(response.data)
            setEmployeeData({ ...response.data });
        } else {
            response.data.errors.forEach(function (key, value) {
                let temp = Object.values(key);
                NotificationWithIcon("error", "Something Went Wrong", temp[0]);
            });
        }
    };

    const handleSave = async () => {
        setEditMode(false);
        try {
            const values = await editForm.validateFields();
            const formData = new window.FormData();
            Object.keys(values).forEach(key => {
                if (values[key] !== undefined) {
                    formData.append(key, values[key]);
                } else if (Array.isArray(values[key])) {
                    values[key].forEach((tag, index) => {
                        formData.append(`${key}[${index}]`, tag);
                    });
                }
            });
            if (image && image instanceof File) {
                formData.append("image", image);
            }
            const response = await updateEmployee(formData, employee.id);
            handleAPIResponse(response.data);
        } catch (error) {
            console.error('Failed to save data', error);
            // Handle errors (e.g., show an error message)
        }
    };

    return (
        <Card style={{ width: '100%', overflow: 'hidden', ...cardStyle }}>
            <Form form={editForm} onFinish={handleSave} layout="vertical">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* Image Column */}
                        <div style={{ marginRight: '15px' }}>
                            {editMode ? (
                                <ImageUpload
                                    name="employee_picture"
                                    callbackSetImageFile={setImage}
                                    image={image}
                                />
                            ) : (employeeData.image ? (
                                <img
                                    src={employeeData.image}
                                    alt="employee pictuer"
                                    style={{ width: '80px', height: '80px', borderRadius: '50%', border: '2px solid #f0f0f0' }}
                                />
                            ) : (
                                <UserOutlined style={{ fontSize: "50px" }} />
                            )
                            )}
                        </div>
                        {/* Info Column */}
                        <div>
                            {editMode ? (
                                <Form.Item name={'name'} label="Employee Name" rules={[{ required: true, message: 'Please input the name' }]}>
                                    <Input />
                                </Form.Item>
                            ) : (
                                <Text>{employeeData.name}</Text>
                            )}
                        </div>
                    </div>
                    <div>
                        {editMode ? (
                            <>
                                <div><Button onClick={handleSave} type="primary" style={{ marginRight: '10px' }}>
                                    <SaveOutlined /> Save
                                </Button>
                                    <Button onClick={handleCancel}>
                                        <CloseOutlined /> Cancel
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <Button onClick={handleEdit} type="primary">
                                <EditOutlined /> Edit Employee
                            </Button>
                        )}
                    </div>
                </div>
                <Divider style={{ margin: '10px 0' }} />
                {/* Lower Section for Additional Data */}
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 10px 10px 10px' }}>
                    {/* <Text>Employee Type: {editMode ? <Input value={employeeData.type} onChange={(e) => setEmployeeData({ ...employeeData, type: e.target.value })} /> : employeeData.type}</Text> */}


                    <Text>Email: {editMode ?
                        (<Form.Item name={'email'} rules={[{ required: true, message: 'Please type email' }]}>
                        <Input />
                    </Form.Item>)
                        : employeeData.email}</Text>
                    <div>
                        Status: {editMode ? (
                            <Form.Item name={'status'} rules={[{ required: true, message: 'Please select a status' }]}>
                                <Select style={{ width: 120 }}>
                                    <Option value="active">Active</Option>
                                    <Option value="inactive">Inactive</Option>
                                </Select>
                            </Form.Item>
                        ) : (
                            <Tag color={employeeData.status === 'Active' ? 'green' : 'volcano'} style={{ textTransform: 'capitalize' }}>{employeeData?.status}</Tag>
                        )}
                    </div>
                </div>
            </Form>
        </Card>
    );
};

export default EmployeeProfile;
