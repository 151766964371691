import React, { useContext, useEffect, useState } from 'react';
import { Timeline, Button, Card, Popconfirm, Checkbox } from 'antd';
import { logs } from '../../network/network';
import { CalendarOutlined, DeleteOutlined, DeleteTwoTone, EditOutlined, FileTextOutlined, PlusCircleOutlined, UserOutlined } from '@ant-design/icons';
import { AppContext } from "../../context/AppContextProvider";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
dayjs.extend(utc);

const Logs = ({ entityType, entityId, refresh, onEditActivity, onDeleteActivity, onEditNote, onDeleteNote, handleUpdateActivityStatus }) => {
    const { authUser } = useContext(AppContext);
    const [logData, setLogData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchLogs();
    }, [refresh]);

    const fetchLogs = async () => {
        setLoading(true);
        try {
            const response = await logs(entityType, entityId);
                setLogData(response.data.data.activity_logs);
        } catch (error) {
            console.error('Failed to fetch logs:', error);
        } finally {
            setLoading(false);
        }
    };

    const logItem = (log) => {
        return {
            label: <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#555' }}>{dayjs(log.created_at).fromNow()}</span>,
            dot: log.event === 'created' ? <PlusCircleOutlined style={{ color: 'green' }} /> :
                log.event === 'updated' ? <EditOutlined style={{ color: 'blue' }} /> :
                    log.event === 'deleted' ? <DeleteOutlined style={{ color: 'red' }} /> : null,
            children: (
                <div>
                    <p style={{ fontSize: '14px', color: '#666', marginBottom: '5px' }}>
                        {log.user?.name + " " + log.event + " customer"}
                    </p>                        {log.changes && Object.keys(log.changes).length > 0 && (
                        <div>
                            <h4 style={{ margin: '0px', color: '#1890ff' }}>Changes:</h4>
                            <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
                                {Object.entries(log.changes).map(([key, value]) => (
                                    <li key={key} style={{ background: '#f0f2f5', margin: '5px 0', padding: '5px 10px', borderRadius: '5px' }}>
                                        <strong>{key}:</strong> {value}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            )
        };
    };

    const activityTimelineItem = (activity) => {
        return {
            label: <span style={{
                fontSize: '14px', fontWeight: 'bold', color: '#555', borderLeft: activity.status === 'completed' ? '3px solid #52c41a' : '3px solid #faad14',
                background: activity.status === 'completed' ? '#f6ffed' : '#fffbe6',
                padding: '10px',
                borderRadius: '5px',
            }}>{dayjs(activity.created_at).fromNow()}</span>,
            dot: <CalendarOutlined style={{ color: activity.status === 'completed' ? '#52c41a' : '#faad14' }} />,
            children: (
                <div style={{
                    borderLeft: activity.status === 'completed' ? '3px solid #52c41a' : '3px solid #faad14',
                    background: activity.status === 'completed' ? '#f6ffed' : '#fffbe6',
                    padding: '10px',
                    borderRadius: '5px',
                    marginBottom: '10px'
                }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h4 style={{ margin: '0px', color: '#1890ff' }}>{activity.title}</h4>
                        {activity.user.id === authUser.id && (
                            <div>
                                <Checkbox
                                    checked={activity.status === 'completed'}
                                    onChange={() => handleUpdateActivityStatus({...activity, status: activity.status === 'completed' ? 'pending' : 'completed' })}
                                >
                                </Checkbox>
                                <Button
                                    type="link"
                                    icon={<EditOutlined />}
                                    onClick={() => onEditActivity(activity)}
                                >
                                    Edit
                                </Button>
                                <Popconfirm
                                    title="Are you sure to delete this entity? All related data will be deleted."
                                    onConfirm={() => onDeleteActivity(activity.id)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <DeleteTwoTone style={{ padding: 6, fontSize: "18px" }}
                                        twoToneColor="#ff0000"
                                        title="Delete Entity"
                                    >
                                    </DeleteTwoTone>
                                </Popconfirm>
                            </div>
                        )}
                    </div>
                    <p style={{ fontSize: '14px', color: '#666', marginBottom: '5px' }}>
                        <UserOutlined /> {activity.user?.name} added an activity.
                    </p>
                    <div>
                        <p style={{ background: '#fff', margin: '5px 0', padding: '5px 10px', borderRadius: '5px' }}>
                            {activity.description}
                        </p>
                        <p style={{ fontSize: '14px', color: '#666' }}>
                            {activity.assigned_to ? (
                                <>
                                    <span style={{ fontWeight: 'bold' }}>Assigned Employee:</span> {activity.assigned_to.name}
                                </>
                            ) : ''}
                            <br />
                            <span style={{ fontWeight: 'bold' }}>Reminder:</span> {activity.reminder ? 'ON' : 'OFF'}
                        </p>
                    </div>
                </div>
            )
        };
    };

    const noteTimelineItem = (note) => {
        return {
            label: <span style={{
                fontSize: '14px', fontWeight: 'bold', color: '#555'
            }}>{dayjs(note.created_at).fromNow()}</span>,
            dot: <FileTextOutlined style={{ color: 'grey' }} />,
            children: (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <></>
                        {note.user.id === authUser.id && (
                            <div>
                                <Button
                                    type="link"
                                    icon={<EditOutlined />}
                                    onClick={() => onEditNote(note)}
                                >
                                    Edit
                                </Button>
                                <Popconfirm
                                    title="Are you sure to delete this entity? All related data will be deleted."
                                    onConfirm={() => onDeleteNote(note.id)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <DeleteTwoTone style={{ padding: 6, fontSize: "18px" }}
                                        twoToneColor="#ff0000"
                                        title="Delete Entity"
                                    >
                                    </DeleteTwoTone>
                                </Popconfirm>
                            </div>
                        )}
                    </div>
                    <p style={{ fontSize: '14px', color: '#666', marginBottom: '5px' }}>
                        <UserOutlined /> {note.user?.name} added a note.
                    </p>
                    <div>
                        <p className="paper-texture">
                            {note.description}
                        </p>
                    </div>
                </div>
            )
        };
    };

    return (
        <>
            <div style={{ textAlign: 'left' }}>
                <h2 style={{ borderBottom: '2px solid #eee', paddingBottom: '10px' }}>Activity Logs</h2>
            </div>
            <Timeline style={{width: '100%'}} mode="left" items={logData.map(activityLogNote => (
                activityLogNote.module_type === 'log' ? logItem(activityLogNote) : (activityLogNote.module_type === 'activity' ? activityTimelineItem(activityLogNote) : noteTimelineItem(activityLogNote))
            ))} />
        </>
    );
};

export default Logs;
