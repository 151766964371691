import React from 'react';
import { Typography } from 'antd';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const { Text } = Typography;

const GoogleMapsCard = ({ center }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  });

  const containerStyle = {
    width: '100%',
    height: '200px'
  };

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
        >
          <Marker position={center} />
        </GoogleMap>
      ) : <Text>Loading Map...</Text>}
      </>
  );
};

export default GoogleMapsCard;
