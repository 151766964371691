import React, { useEffect, useMemo, useState } from 'react';
import { Button, DatePicker, Divider, Form, Input, Select, Tag, Typography } from 'antd';
import { IdcardOutlined, GlobalOutlined, SaveOutlined, CloseOutlined, EditOutlined, BankOutlined, UserOutlined } from '@ant-design/icons';
import { updateEmployee } from '../../../../../../network/network';
import dayjs from 'dayjs';
import NotificationWithIcon from '../../../../../../components/NotificationWithIcon';

const { Text, Title } = Typography;


const GeneralInfo = ({ employee, cardStyle, employeeRawData }) => {
    const [editForm] = Form.useForm();
    const [editMode, setEditMode] = useState(false);
    const [employeeData, setEmployeeData] = useState({
        ...employee,
    });

    useEffect(() => {
        setEmployeeData({
            ...employee,
        });
    }, [employee])

    const handleEdit = () => {
        setEditMode(true);
        editForm.setFieldsValue({
            id: employeeData.id,
            dob: dayjs(employeeData.dob),
            contact: employeeData.contact,
            department_id: employeeData.department.id,
            designation_id: employeeData.designation.id,
        });
    };

    const handleCancel = () => {
        editForm.resetFields();
        setEditMode(false);
    };


    const handleAPIResponse = (response) => {
        if (response.success === true) {
            NotificationWithIcon("success", `Employee updated successfully`, `Employee updated Successfully`);
            editForm.resetFields();
            setEmployeeData({ ...response.data });
        } else {
            response.data.errors.forEach(function (key, value) {
                let temp = Object.values(key);
                NotificationWithIcon("error", "Something Went Wrong", temp[0]);
            });
        }
    };

    const handleSave = async () => {
        setEditMode(false);
        try {
            const values = {...await editForm.validateFields(), 'name': employee.name};
            const response = await updateEmployee(values, employee.id);
            handleAPIResponse(response.data);
        } catch (error) {
            console.error('Failed to save data', error);
            // Handle errors (e.g., show an error message)
        }
    };

    return (
        <div style={{ width: '100%', overflow: 'hidden', ...cardStyle, display: 'flex', justifyContent: 'space-between' }} bordered={false}>
            <Form form={editForm} onFinish={handleSave} layout="vertical">
                <Title level={4}><IdcardOutlined /> General Information</Title>
                <Divider />
                <div style={{ marginBottom: '8px' }}>
                    <Text strong>Date of Birth: </Text>
                    {editMode ? (
                        <Form.Item name="dob" label="Date Of Birth" hasFeedback>
                        <DatePicker
                          format="YYYY-MM-DD"
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    ) : (<Text>{employeeData?.dob}</Text>)}
                </div>
                <div style={{ marginBottom: '8px' }}>
                    <Text strong>Contact: </Text>
                    {editMode ? (
                        <Form.Item name={'contact'} rules={[{ required: true, message: 'Please input the contact' }]}>
                            <Input />
                        </Form.Item>
                    ) : (
                        <Text>{employeeData?.contact}</Text>
                    )}
                </div>
                <div style={{ marginBottom: '8px' }}>
                    <Text strong>Department: </Text>
                    {editMode ? (
                        <Form.Item name={'department_id'} rules={[{ required: true, message: 'Please select the department' }]}>
                            <Select
                                showSearch
                                options={employeeRawData.departments?.map((department) => {
                                    return {
                                        label: department.name,
                                        value: department.id,
                                    };
                                })}
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    ) : (
                        <Tag icon={<BankOutlined />} color="blue">{employeeData?.department?.name}</Tag>
                    )}
                </div>
                <div style={{ marginBottom: '8px' }}>
                    <Text strong>Designation: </Text>
                    {editMode ? (
                        <Form.Item name={'designation_id'} rules={[{ required: true, message: 'Please select the designation' }]}>
                            <Select
                                showSearch
                                options={employeeRawData.designations?.map((designation) => {
                                    return {
                                        label: designation.name,
                                        value: designation.id,
                                    };
                                })}
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    ) : (
                        <Tag icon={<UserOutlined />} color="blue">{employeeData?.designation?.name}</Tag>
                    )}
                </div>
            </Form>
            {editMode ? (
                <>
                    <div><Button onClick={handleSave} type="primary" style={{ marginRight: '10px' }}>
                        <SaveOutlined /> Save
                    </Button>
                        <Button onClick={handleCancel}>
                            <CloseOutlined /> Cancel
                        </Button>
                    </div>
                </>
            ) : (
                <Button onClick={handleEdit} type="primary">
                    <EditOutlined /> Edit employee
                </Button>
            )}
        </div>
    );
};

export default GeneralInfo;
