import "./Home.css";
import { useContext } from "react";
import { Col, Row, Card } from "antd";

import { AppContext } from "../../../context/AppContextProvider";

const Home = () => {
  const { authUser } = useContext(AppContext);

  return (
    <>
      <Row style={{ marginBottom: 16 }} gutter={16}>
        <Col span={24}>
          <Card className="fade-in-from-left">
            <h3>{"Hi " + authUser.name + " 👋, Welcome to Truckala!"}</h3>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Home;
