import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Table,
  Popconfirm,
  Radio,
  message,
  Space,
  Drawer,
  Row,
  Col,
  Select,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  addFleetType,
  getFleetTypes,
  deleteFleetType,
  updateFleetType,
  rawDataFleetType,
} from "../../../../network/network";
import NotificationWithIcon from "../../../../components/NotificationWithIcon";
import {
  DeleteTwoTone,
  EditTwoTone,
  LockOutlined,
  MinusCircleFilled,
  PlusCircleFilled,
  UnlockOutlined,
} from "@ant-design/icons";

const FleetTypes = () => {
  const ADD_FLEET_TYPE = "Add Fleet Type";
  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [fleetTypeData, setFleetTypeData] = useState([]);
  const [fleetTypeRawData, setFleetTypeRawData] = useState([]);
  const [drawerTitle, setDrawerTitle] = useState(ADD_FLEET_TYPE);
  const [fleetTypeForm] = Form.useForm();

  useEffect(() => {
    rawDataFleetType().then((response) => {
      setFleetTypeRawData(response.data.data);
    });
  }, []);

  useEffect(() => {
    setIsDataLoading(true);
    getFleetTypes()
      .then((response) => {
        setFleetTypeData(response.data.data.fleetTypes);
      })
      .finally(() => {
        setIsDataLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [reloadTableData]);

  const showDrawer = () => {
    setDrawerTitle("Add Fleet Type");
    setDrawerVisible(true);
  };

  const handleAPIResponse = (response, type) => {
    let data = response.data || response.response.data;
    if (data.success === true) {
      setReloadTableData(!reloadTableData);
      // Success
      if (type === ADD_FLEET_TYPE) {
        NotificationWithIcon(
          "success",
          "Fleet Type Added",
          "Fleet Type added successfully!"
        );
      } else {
        NotificationWithIcon(
          "success",
          "Fleet Type Updated",
          "Fleet Type updated successfully!"
        );
      }
    } else {
      // Error
      let errorMessages = Object.values(data.errors);
      errorMessages.forEach(function (key, value) {
        let temp = Object.values(key);
        NotificationWithIcon("error", "Something Went Wrong", temp[0]);
      });
    }
    fleetTypeForm.resetFields();
    setDrawerVisible(false);
  };

  const handleAddEdit = () => {
    fleetTypeForm
      .validateFields()
      .then((values) => {
        values.fleet_types_load = [
          {
            load_type_id: values.load_type_id,
            capicity: values.capicity,
            weight: values.weight,
          },
        ];
        // Set Loading True
        setLoading(true);
        // Sending Request to API
        if (
          drawerTitle === ADD_FLEET_TYPE ||
          drawerTitle === "Add Fleet Type"
        ) {
          addFleetType(values)
            .then((response) => {
              handleAPIResponse(response, ADD_FLEET_TYPE);
            })
            .catch((info) => {
              console.log("API Failed:", info);
            //   message.error(info);
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        } else {
          updateFleetType(values)
            .then((response) => handleAPIResponse(response, null))
            .catch((info) => {
              console.log("API Failed:", info.response);
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        }
      })
      .catch((info) => {
        console.log("API Failed:", info);
      });
  };

  const handleCancel = () => {
    // Reset Form Fields
    fleetTypeForm.resetFields();
    setDrawerVisible(false);
  };

  const handleEditDrawer = (record) => {
    fleetTypeForm.resetFields();
    setDrawerTitle("Edit Fleet Type : " + record.name);
    // Set Form Fields
    console.log(record);
    fleetTypeForm.setFieldsValue({
      id: record.id,
      name: record.name,
      status: record.status,
      capicity: record.capicity,
      weight: record.weight,
      load_type_id: record.load_types.type,
    });
    setDrawerVisible(true);
  };

  const handleDelete = (id) => {
    deleteFleetType(id)
      .then((response) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
          // Success
          setReloadTableData(!reloadTableData);
          NotificationWithIcon("success", "Fleet Type Deleted", data.message);
        } else {
          // Error
          NotificationWithIcon("error", "Something Went Wrong", data.message);
        }
      })
      .catch((info) => {
        // Error
        NotificationWithIcon(
          "error",
          "Something Went Wrong",
          info.response.data.message
        );
      });
  };

  const tableColumns = [
    {
      title: "#",
      key: "index",
      width: "5%",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Document Type",
      render: (record) => (
        <>{record.load_types ? record.load_types.type : "-"}</>
      ),
    },
    {
      title: "Capacity",
      dataIndex: "capicity",
    },
    {
      title: "Weight",
      dataIndex: "weight",
    },
    {
      title: "Status",
      align: "center",
      render: (record) => {
        return (
          <>
            {record.status === "Active" ? (
              <UnlockOutlined
                title="Active"
                style={{ color: "green", fontSize: "18px" }}
              />
            ) : (
              <LockOutlined
                title="Inactive"
                style={{ color: "red", fontSize: "18px" }}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Action",
      align: "center",
      width: "100px",
      render: (record) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
            <Button
              type="link"
              onClick={() => {
                handleEditDrawer(record);
              }}
              title="Edit record"
              style={{
                padding: 6,
                fontSize: "18px",
                borderRadius: "5px",
                backgroundColor: "rgba(24, 144, 255, 0.2)",
                display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
              }}
            >
              <EditTwoTone twoToneColor="#1890ff" />
            </Button>
            <Popconfirm
              title="Are you sure to delete this entity?"
              onConfirm={() => handleDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteTwoTone
                style={{
                    padding: 6,
                    fontSize: "18px",
                    borderRadius: "5px",
                    backgroundColor: "rgba(255, 0, 0, 0.2)",
                    display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                  }}
                twoToneColor="#ff0000"
                title="Delete Entity"
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onFinish = (values) => {
    console.log(values);
  };

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <PageHeader
          style={{ padding: "0" }}
          title="Fleet Types"
          ghost={false}
          extra={[
            <Button type="primary" onClick={showDrawer} key="add-fleet-type">
              + Add Fleet Type
            </Button>,
          ]}
        />
      </Card>
      <Card>
        <Table
          columns={tableColumns}
          dataSource={fleetTypeData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
          bordered
        />
      </Card>
      {/* Add Edit FleetType Modal */}
      <Drawer
        title={drawerTitle}
        placement="right"
        onClose={handleCancel}
        open={drawerVisible}
        width={"calc(100% - 200px)"}
      >
        <Form
          onFinish={onFinish}
          name="add-form"
          // onFinish={handleAddEdit}
          layout="vertical"
          style={{ width: "100%", margin: "0" }}
          form={fleetTypeForm}
        >
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 20,
              lg: 20,
              xl: 20,
              xxl: 20,
            }}
          >
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Validate Fleet Type Required",
                    whitespace: true,
                  },
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <h3>Fleet Types Load</h3>
              <Row
                align="middle"
                gutter={{
                  xs: 8,
                  sm: 16,
                  md: 20,
                  lg: 20,
                  xl: 20,
                  xxl: 20,
                }}
              >
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item label="Load Type" name="load_type_id" hasFeedback>
                    <Select
                      showSearch
                      allowClear
                      options={fleetTypeRawData.load_types?.map((type) => {
                        return {
                          label: type.type,
                          value: type.id,
                        };
                      })}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
                  <Form.Item
                    name="capicity"
                    label="Capicity"
                    rules={[
                      {
                        required: true,
                        message: "Validate Capicity Required",
                        whitespace: true,
                      },
                    ]}
                    hasFeedback
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
                  <Form.Item
                    name="weight"
                    label="Weight"
                    rules={[
                      {
                        required: true,
                        message: "Validate Weight Required",
                        whitespace: true,
                      },
                    ]}
                    hasFeedback
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={2} lg={2} xl={2} xxl={2}>
                  <Space>
                    <MinusCircleFilled
                      style={{
                        color: "#6301f2",
                        fontSize: "26px",
                      }}
                    />
                    <PlusCircleFilled
                      style={{
                        color: "#6301f2",
                        fontSize: "26px",
                      }}
                    />
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              {!(
                drawerTitle === ADD_FLEET_TYPE ||
                drawerTitle === "Add Fleet Type"
              ) && (
                <Form.Item name="status" label="Status">
                  <Radio.Group buttonStyle="solid" key="status">
                    <Radio.Button key="inactive" value="Inactive">
                      Inactive
                    </Radio.Button>
                    <Radio.Button key="active" value="Active">
                      Active
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              )}
            </Col>
            <Form.Item name="id" style={{ display: "none" }}>
              <input type="hidden" />
            </Form.Item>
          </Row>
        </Form>
        <Row justify="end" style={{ marginBottom: "20px" }}>
          <Space size={20}>
            <Button key="back" loading={loading} onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleAddEdit}
            >
              {drawerTitle === ADD_FLEET_TYPE ||
              drawerTitle === "Add Fleet Type"
                ? "Add Fleet Type"
                : "update"}
            </Button>
          </Space>
        </Row>
      </Drawer>
    </>
  );
};

export default FleetTypes;
