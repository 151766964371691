import React, { useEffect, useMemo, useState } from 'react';
import { Button, Divider, Form, Input, Select, Tag, Typography } from 'antd';
import { IdcardOutlined, GlobalOutlined, SaveOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import countryList from 'react-select-country-list';
import NotificationWithIcon from '../../../../../components/NotificationWithIcon';
import { updateCustomer } from '../../../../../network/network';

const { Text, Title } = Typography;


const GeneralInfo = ({ customer, cardStyle }) => {
    const countries = useMemo(() => countryList().getData(), []);

    const [editForm] = Form.useForm();
    const [editMode, setEditMode] = useState(false);
    const [customerData, setCustomerData] = useState({
        ...customer,
    });

    useEffect(() => {
        setCustomerData({
            ...customer,
        });
    }, [customer])

    const handleEdit = () => {
        setEditMode(true);
        editForm.setFieldsValue({
            id: customerData.id,
            nationality: customerData.nationality,
            tax_id: customerData.tax_id,
            address: customerData.address,
        });
    };

    const handleCancel = () => {
        editForm.resetFields();
        setEditMode(false);
    };


    const handleAPIResponse = (response) => {
        if (response.success === true) {
            NotificationWithIcon("success", `Customer updated successfully`, `Customer updated Successfully`);
            editForm.resetFields();
            setCustomerData({ ...response.data.customer });
            console.log(response.data.customer)
        } else {
            response.data.errors.forEach(function (key, value) {
                let temp = Object.values(key);
                NotificationWithIcon("error", "Something Went Wrong", temp[0]);
            });
        }
    };

    const handleSave = async () => {
        setEditMode(false);
        try {
            const values = await editForm.validateFields();
            const response = await updateCustomer(values, customer.id);
            handleAPIResponse(response.data);
        } catch (error) {
            console.error('Failed to save data', error);
            // Handle errors (e.g., show an error message)
        }
    };

    return (
        <div style={{ width: '100%', overflow: 'hidden', ...cardStyle, display: 'flex', justifyContent: 'space-between' }} bordered={false}>
            <Form form={editForm} onFinish={handleSave} layout="vertical">
                <Title level={4}><IdcardOutlined /> General Information</Title>
                <Divider />
                <div>
                    <Text strong>Country: </Text>
                    {editMode ? (
                        <Form.Item
                            name="nationality"
                            rules={[
                                {
                                    required: true,
                                    message: "Validate Country Required",
                                    whitespace: true,
                                },
                            ]}
                            hasFeedback
                        >
                            <Select
                                showSearch
                                options={countries}
                                value={customerData.nationality}
                                // onChange={changeHandler}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            />
                        </Form.Item>
                    ) : (<Tag icon={<GlobalOutlined />} color="blue">{countries.find(country => country.value === customerData?.nationality)?.label}</Tag>)}
                </div>
                <div>
                    <Text strong>Tax ID: </Text>
                    {editMode ? (
                        <Form.Item name={'tax_id'} rules={[{ required: true, message: 'Please input the tax id' }]}>
                            <Input />
                        </Form.Item>
                    ) : (
                        <Text>{customerData.tax_id}</Text>
                    )}
                </div>
                <div>
                    <Text strong>Address: </Text>
                    {editMode ? (
                        <Form.Item name={'address'} rules={[{ required: true, message: 'Please input the address' }]}>
                            <Input />
                        </Form.Item>
                    ) : (
                        <Text>{customerData.address}</Text>
                    )}
                </div>
            </Form>
            {editMode ? (
                <>
                    <div><Button onClick={handleSave} type="primary" style={{ marginRight: '10px' }}>
                        <SaveOutlined /> Save
                    </Button>
                        <Button onClick={handleCancel}>
                            <CloseOutlined /> Cancel
                        </Button>
                    </div>
                </>
            ) : (
                <Button onClick={handleEdit} type="primary">
                    <EditOutlined /> Edit Customer
                </Button>
            )}
        </div>
    );
};

export default GeneralInfo;
