import Environment from "./baseUrl";
import axios from "axios";

// const getHeaders = async (token) => {
//   if (token) {
//     return {
//       Authorization: "Bearer " + token,
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       "Access-Control-Allow-Origin": "*",
//     };
//   } else {
//     return {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       // 'Authorization':  APP_NORMAL,
//     };
//   }
// };

const getHeaders = async (token, isFileUpload = false) => {
    const headers = {
      Authorization: token ? "Bearer " + token : undefined,
      "Content-Type": isFileUpload ? undefined : "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    if (!token) {
      delete headers.Authorization;
    }
    if (isFileUpload) {
      delete headers["Content-Type"];
    }
    return headers;
  };

var authOptions = {
  method: null,
  data: null,
  headers: getHeaders(),
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    if (error.response.status === 401) {
      //When Unauthorized
    //   localStorage.removeItem("last_visited_page");
    //   localStorage.removeItem("token");
    //   window.location = "/login";
    }
    return error;
  }
);

export const doPost = async (url, data, token, isFileUpload = false) => {
  authOptions.method = "POST";
  authOptions.data = data;
  authOptions.params = {};
  if (token) {
    authOptions.headers = await getHeaders(token, isFileUpload);
  }
  return axios(Environment.API_BASE_URL + url, authOptions);
};
export const doPatch = async (url, data, token, isFileUpload = false) => {
  authOptions.method = "POST";
  authOptions.data = data;
  authOptions.headers = await getHeaders(token, isFileUpload);
  authOptions.params = { _method: 'PATCH' };
  return axios(Environment.API_BASE_URL + url, authOptions);
};
export const doPut = async (url, data, token, isFileUpload = false) => {
  authOptions.method = "POST";
  authOptions.data = data;
  authOptions.headers = await getHeaders(token, isFileUpload);
  authOptions.params = { _method: 'PUT' };
  return axios(Environment.API_BASE_URL + url, authOptions);
};
export const doGet = async (url, token, data) => {
  authOptions.headers = await getHeaders(token);
  authOptions.method = "GET";
  authOptions.data = null;
  authOptions.params = {};
  if (data) {
    var params = Object.keys(data)
      .map((key) => key + "=" + data[key])
      .join("&");

    if (params) {
      url = url + "?" + params;
    }
  }
  return axios(Environment.API_BASE_URL + url, authOptions);
};
export const doDelete = async (url, data, token) => {
  authOptions.method = "DELETE";
  authOptions.data = data;
  authOptions.headers = await getHeaders(token);
  authOptions.params = { _method: 'DELETE' };
  return axios(Environment.API_BASE_URL + url, authOptions);
};
