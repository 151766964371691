import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Table,
  Modal,
  Popconfirm,
  Radio,
  message,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  addService,
  getService,
  deleteService,
  updateService,
} from "../../../../network/network";
import NotificationWithIcon from "../../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";
import {
  DeleteTwoTone,
  EditTwoTone,
  LockOutlined,
  UnlockOutlined,
} from "@ant-design/icons";

const Services = () => {
  const ADD_SERVICE = "Add Service";
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [servicesData, setServicesData] = useState([]);
  const [modalTitle, setModalTitle] = useState(ADD_SERVICE);
  const [serviceForm] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    setIsDataLoading(true);
    getService()
      .then((response) => {
        setServicesData(response.data.data.services);
      })
      .finally(() => {
        setIsDataLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [reloadTableData]);

  const showModal = () => {
    // Set Modal Title
    setModalTitle(t("service_add"));
    // Show Modal
    setModalVisible(true);
  };

  const handleAPIResponse = (response, type) => {
    let data = response.data || response.response.data;
    if (data.success === true) {
      setReloadTableData(!reloadTableData);
      // Success
      if (type === ADD_SERVICE) {
        NotificationWithIcon(
          "success",
          "Service Added",
          "Service has been added successfully!"
        );
      } else {
        NotificationWithIcon(
          "success",
          "Service Updated",
          "Service has been updated successfully!"
        );
      }
    } else {
      // Error
      let errorMessages = Object.values(data.data.errors);
      errorMessages.forEach(function (key, value) {
        let temp = Object.values(key);
        message.error(temp[0]);
      });
    }
    // Reset Form Fields
    serviceForm.resetFields();
    // Hide Modal
    setModalVisible(false);
  };

  const handleAddEdit = () => {
    serviceForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        // Sending Request to API
        if (modalTitle === ADD_SERVICE || modalTitle === t("service_add")) {
          addService(values)
            .then((response) => handleAPIResponse(response, ADD_SERVICE))
            .catch((info) => {
              console.log("API Failed:", info.response);
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        } else {
          updateService(values)
            .then((response) => handleAPIResponse(response, null))
            .catch((info) => {
              console.log("API Failed:", info.response);
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        }
      })
      .catch((info) => {
        console.log("API Failed:", info);
      });
  };

  const handleCancel = () => {
    // Reset Form Fields
    serviceForm.resetFields();
    setModalVisible(false);
  };

  const handleEditModal = (record) => {
    serviceForm.resetFields();
    // Set Modal Title
    setModalTitle("Edit Service : " + record.service);
    // Set Form Fields
    serviceForm.setFieldsValue({
      id: record.id,
      service: record.service,
      status: record.status,
    });
    // Show Modal
    setModalVisible(true);
  };

  const handleDelete = (id) => {
    deleteService(id)
      .then((response) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
          // Success
          setReloadTableData(!reloadTableData);
          NotificationWithIcon(
            "success",
            "Service Deleted",
            "Service has been deleted successfully!"
          );
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            data.message
          );
        }
      })
      .catch((info) => {
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          info.response.data.message
        );
      });
  };

  const tableColumns = [
    {
      title: "#",
      key: "index",
      width: "5%",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("name"),
      dataIndex: "service",
    },
    {
      title: t("col_status"),
      align: "center",
      dataIndex: "status",
      render: (status) => {
        return (
          <>
            {status === "Active" ? (
              <UnlockOutlined
                title={t("active")}
                style={{ color: "green", fontSize: "18px" }}
              />
            ) : (
              <LockOutlined
                title={t("inactive")}
                style={{ color: "red", fontSize: "18px" }}
              />
            )}
          </>
        );
      },
    },
    {
      title: t("col_action"),
      align: "center",
      width: "100px",
      render: (record) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
            <Button
              type="link"
              onClick={() => {
                handleEditModal(record);
              }}
              title="Edit record"
              style={{
                padding: 6,
                fontSize: "18px",
                borderRadius: "5px",
                backgroundColor: "rgba(24, 144, 255, 0.2)",
                display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
              }}
            >
              <EditTwoTone twoToneColor="#1890ff" />
            </Button>
            <Popconfirm
              title="Are you sure to delete this entity?"
              onConfirm={() => handleDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteTwoTone
                style={{
                    padding: 6,
                    fontSize: "18px",
                    borderRadius: "5px",
                    backgroundColor: "rgba(255, 0, 0, 0.2)",
                    display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                  }}
                twoToneColor="#ff0000"
                title="Delete Entity"
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_services")}
          ghost={false}
          extra={[
            <Button type="primary" onClick={showModal} key="add-service">
              + {t("service_add")}
            </Button>,
          ]}
        />
      </Card>
      <Card>
        <Table
          columns={tableColumns}
          dataSource={servicesData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
          bordered
        />
      </Card>
      {/* Add Edit Service Modal */}
      <Modal
        open={modalVisible}
        title={modalTitle}
        onOk={handleAddEdit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleAddEdit}
          >
            {modalTitle === ADD_SERVICE || modalTitle === t("service_add")
              ? t("add")
              : t("update")}
          </Button>,
        ]}
      >
        <Form
          name="service-form"
          onFinish={handleAddEdit}
          layout="vertical"
          style={{ width: "100%", margin: "0" }}
          form={serviceForm}
        >
          <Form.Item
            name="service"
            label="Service Name"
            rules={[
              {
                required: true,
                message: t("validate_service_required"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          {!(modalTitle === ADD_SERVICE || modalTitle === t("service_add")) && (
            <Form.Item name="status" label="Status">
              <Radio.Group buttonStyle="solid" key="status">
                <Radio.Button key="inactive" value="Inactive">
                  Inactive
                </Radio.Button>
                <Radio.Button key="active" value="Active">
                  Active
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          )}
          <Form.Item name="id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Services;
