import "./Dashboard.css";
import React, { useEffect, useState, useContext } from "react";
import { Menu, Layout, Radio } from "antd";
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    DownOutlined,
} from "@ant-design/icons";
import { AppContext } from "../../context/AppContextProvider";
import AppRoutes, { ContentPages } from "../../routes";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { updateAuthDetails, userLogout } from "../../network/network";
import { filterPagesByAddons } from "../../utils";
import Home from "../Content/Home/Home";
import {
    COMPANY_STATUS_ACTIVE,
    COMPANY_STATUS_BLOCKED,
    COMPANY_STATUS_SUBSCRIPTION_ENDED,
    COMPANY_STATUS_SUBSCRIPTION_IN_REVIEW,
    COMPANY_STATUS_SUBSCRIPTION_INVOICE_GENERATED,
} from "../../configs/constants";

const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;

const Dashboard = () => {
    const {
        authUser,
        activeMenu,
        setActiveMenu,
        appConfig,
        setAppConfigWrapper,
    } = useContext(AppContext);
    const [isSiderCollapsed, setIsSiderCollapsed] = useState(false);
    const [showPageOnly, setShowPageOnly] = useState(false);
    const [blockRouting, setBlockRouting] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (authUser.company_status === COMPANY_STATUS_ACTIVE) {
            setBlockRouting(false);
            setShowPageOnly(false);
        } else {
            if (authUser.company_status === COMPANY_STATUS_SUBSCRIPTION_ENDED) {
                setBlockRouting(true);
                setShowPageOnly("SUBSCRIPTION");
            } else if (
                authUser.company_status === COMPANY_STATUS_SUBSCRIPTION_IN_REVIEW
            ) {
                setBlockRouting(true);
                setShowPageOnly("SUBSCRIPTION");
            } else if (authUser.company_status === COMPANY_STATUS_BLOCKED) {
                setBlockRouting(true);
                setShowPageOnly("INVOICES");
            } else if (
                authUser.company_status ===
                COMPANY_STATUS_SUBSCRIPTION_INVOICE_GENERATED
            ) {
                setShowPageOnly("INVOICES");
            }
        }

        setAppConfigWrapper(authUser.app_config);
        // eslint-disable-next-line
    }, [authUser.company_status]);

    useEffect(() => {
        if (
            authUser &&
            Object.keys(appConfig).length > 0 &&
            JSON.stringify(authUser.app_config) !== JSON.stringify(appConfig)
        ) {
            const formData = new FormData();
            formData.append("app_config", JSON.stringify(appConfig));
            updateAuthDetails(formData).then((res) => {
                console.log("App Config Updated");
            });
        }
    }, [appConfig, authUser]);

    const saveCurrentPageView = (key) => {
        setActiveMenu(key);
        localStorage.setItem(
            "last_visited_page",
            JSON.stringify({
                key: key,
                path: window.location.pathname,
            })
        );
        // console.log(localStorage.getItem("last_visited_page"));
    };

    const logoutUser = () => {
        userLogout().then(function () {
            localStorage.removeItem("last_visited_page");
            localStorage.removeItem("token");
            localStorage.removeItem('profile');
            window.location = "/login";
        });
    };

    return (
        <Layout id="app" hasSider={true}>
            <Sider
                collapsible
                theme="light"
                trigger={null}
                defaultCollapsed={false}
                collapsed={isSiderCollapsed}
                collapsedWidth={window.innerWidth > 767 ? 80 : 0}
                // onCollapse={this.onCollapse}
                width={220}
                breakpoint={"md"}
                style={{
                    boxShadow: "0 4px 12px 0 rgba(0,0,0,0.2)",
                    minHeight: "100vh",
                    backgroundColor: "#f0f2f5", // Lighter background
                }}
            >
                <div style={{ padding: "20px", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src="/logo.png" alt="Truckala Logo" width={isSiderCollapsed ? "100%" :"40%"} height="auto" />
                    {!isSiderCollapsed && (<h2 style={{ marginLeft: "10px", fontFamily: "'Ubuntu', 'Roboto', sans-serif", fontWeight: "bold", color: "#1890ff", marginBottom: "10px", fontStyle: "italic" }}>Truckala</h2>)}
                </div>
                <Menu
                    theme="light"
                    mode="inline"
                    selectedKeys={[activeMenu]}
                    style={{ borderRight: "none" }} // Remove the right border

                    items={filterPagesByAddons(ContentPages, authUser)
                        .map((page) => {
                            if (page.showInSider) {
                                if (page.subMenus && page.subMenus.length > 0) {
                                    return {
                                        key: page.name,
                                        icon: React.createElement(page.icon),
                                        label: t(page.text),
                                        disabled: blockRouting,
                                        children: filterPagesByAddons(page.subMenus, authUser).map(
                                            (subPage) => ({
                                                key: subPage.name,
                                                label: <Link to={subPage.path}>{t(subPage.text)}</Link>,
                                                onClick: () => saveCurrentPageView(subPage.name),
                                            })
                                        ),
                                    };
                                } else {
                                    return {
                                        key: page.name,
                                        icon: React.createElement(page.icon),
                                        label: <Link to={page.path}>{t(page.text)}</Link>,
                                        onClick: () => saveCurrentPageView(page.name),
                                        disabled: blockRouting,
                                    };
                                }
                            }
                            return null;
                        })
                        .filter(Boolean)} // Filter out null values
                />
            </Sider>

            <Layout className="site-layout">
                <Header
                    className="site-layout-background"
                    style={{
                        padding: "0 10px",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Menu
                        theme="light"
                        mode="horizontal"
                        style={{
                            borderBottom: "none",
                            minWidth: "300px",
                        }}
                        items={[
                            {
                                key: "sidebar-collapse-menu-item",
                                label: isSiderCollapsed ? (
                                    <MenuUnfoldOutlined
                                        onClick={() => setIsSiderCollapsed(!isSiderCollapsed)}
                                    />
                                ) : (
                                    <MenuFoldOutlined
                                        onClick={() => setIsSiderCollapsed(!isSiderCollapsed)}
                                    />
                                ),
                            },
                            {
                                key: "top-navbar-0",
                                style: {
                                    borderBottom: "none",
                                },
                                label: (
                                    <Radio.Group
                                        value={appConfig.direction}
                                        onChange={(e) => {
                                            setAppConfigWrapper({ direction: e.target.value });
                                        }}
                                        buttonStyle="solid"
                                    >
                                        <Radio.Button value="ltr">English</Radio.Button>
                                        <Radio.Button value="rtl">عربي</Radio.Button>
                                    </Radio.Group>
                                ),
                            },
                        ]}
                    />
                    {authUser.business_name && <h3>{authUser.business_name}</h3>}
                    <Menu
                        theme="light"
                        mode="horizontal"
                        style={{
                            borderBottom: "none",
                            display: "block",
                            minWidth: "300px",
                            textAlign: appConfig.direction === "rtl" ? "left" : "right",
                        }}
                        items={[
                            {
                                key: "top-navbar-3",
                                label: (
                                    <span
                                        className="submenu-title-wrapper"
                                        style={{ width: "150px" }}
                                    >
                                        {authUser.name + " "}
                                        <DownOutlined />
                                    </span>
                                ),
                                children: [
                                    {
                                        key: "31",
                                        label: t("logout"),
                                        onClick: logoutUser,
                                    },
                                ],
                            },
                        ]}
                    />
                </Header>

                <Content
                    className="site-layout-background"
                    style={{
                        margin: "24px 16px",
                        padding: "0",
                        background: "transparent",
                        minHeight: "80vh",
                    }}
                >
                    {blockRouting ? (
                        <>{showPageOnly === "HOME" && <Home />}</>
                    ) : (
                        <AppRoutes />
                    )}
                </Content>

                <Footer
                    style={{
                        textAlign: "center",
                        backgroundColor: "#ffffff",
                        fontWeight: "500",
                        padding: "10px",
                    }}
                >
                    Truckala © {new Date().getFullYear()}
                </Footer>
            </Layout>
        </Layout>
    );
};

export default Dashboard;
