import React, { useState, useEffect } from "react";
import { Card, Button, Table, Input, Tag } from "antd";
import { EyeFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { debounce } from "lodash";
import { getCustomerContracts } from "../../../../../network/network";
import { useNavigate } from "react-router-dom";
dayjs.extend(utc);


const CustomerContractsTab = ({ customerId }) => {
    const navigate = useNavigate();
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [contractsAllData, setContractsAllData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');

    const fetchData = async () => {
        try {
            const contractsResponse = await getCustomerContracts({
                page: currentPage,
                search: searchTerm,
                customer: customerId
            });
            setContractsAllData(contractsResponse.data.data.customerContracts);
            const paginationData = contractsResponse.data.data.pagination;
            setPagination({
                current: paginationData.current_page,
                pageSize: paginationData.per_page,
                total: paginationData.total,
                showSizeChanger: false,
            });
        } catch (error) {
            console.error("Fetching data failed:", error);
        } finally {
            setIsDataLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    const debouncedFetchData = debounce(() => {
        fetchData();
    }, 500);

    useEffect(() => {
        debouncedFetchData();
        // Cleanup to cancel the debounced call if component unmounts or the value changes
        return () => debouncedFetchData.cancel();
    }, [searchTerm]);


    const handleContractsTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
        setCurrentPage(pagination.current);
    };

    const getStatusColor = (statusName) => {
        switch (statusName?.toLowerCase()) {
            case 'draft':
                return 'gold';
            case 'active':
                return 'success';
            case 'expired':
                return 'processing';
            default:
                return 'cyan';
        }
    };

    const tableColumns = [
        {
            title: "#",
            key: "index",
            width: "5%",
            render: (text, record, index) => {
                return ((pagination.current - 1) * pagination.pageSize) + index + 1;
            },
        },
        {
            title: "Contract Name",
            dataIndex: "contract_name",
        },
        {
            title: "Customer",
            render: (record) => (
                <>{record.customer ? record.customer.company_name : "-"}</>
            ),
        },
        {
            title: "Start Date",
            dataIndex: "start_date",
        },
        {
            title: "End Date",
            dataIndex: "end_date",
        },
        {
            title: "Billing Type",
            dataIndex: "billing_type",
        },
        {
            title: 'Status',
            key: 'statusColor',
            width: '5px',
            render: (record) => {
                return (
                    <Tag bordered={false} color={getStatusColor(record.status)}>{record.status}</Tag>
                );
            },
        },
        {
            title: "Action",
            align: "center",
            width: "40px",
            render: (record) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            type="link"
                            onClick={() => {
                                navigate(`/customerContracts/${record.id}`);
                            }}
                            title="View Details"
                            style={{
                                padding: 6,
                                fontSize: "18px",
                                borderRadius: "5px",
                                backgroundColor: "rgba(82, 196, 26, 0.2)",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <EyeFilled style={{ color: "#52c41a" }} />
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <Card>
                <Input
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    onPressEnter={() => fetchData()}
                    style={{ marginBottom: 20, width: '50%' }}
                />
                <Table columns={tableColumns} dataSource={contractsAllData} loading={isDataLoading} rowKey="id" pagination={pagination} onChange={handleContractsTableChange} bordered />
            </Card>
        </>
    );
};

export default CustomerContractsTab;
