import React, { useState, useEffect, useMemo } from "react";
import {
    Card,
    Form,
    Input,
    Button,
    Table,
    Popconfirm,
    message,
    Space,
    Drawer,
    Col,
    Row,
    Select,
    Radio,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
    addCustomer,
    getCustomers,
    deleteCustomer,
    updateCustomer,
} from "../../../../network/network";
import countryList from "react-select-country-list";
import NotificationWithIcon from "../../../../components/NotificationWithIcon";
import {
    BankOutlined,
    DeleteTwoTone,
    EditTwoTone,
    EyeFilled,
    LockOutlined,
    UnlockOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ImageUpload from "../../../../components/ImageUpload";
import { BuildingIcon } from "../../../../components/CustomIcons";

const Customers = () => {
    const ADD_CUSTOMER = "Add Customer";
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(false);
    const [value, setValue] = useState("");
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [reloadTableData, setReloadTableData] = useState(false);
    const [customerAllData, setCustomerAllData] = useState([]);
    const [drawerTitle, setDrawerTitle] = useState(ADD_CUSTOMER);
    const [customersForm] = Form.useForm();
    const navigate = useNavigate();

    useEffect(() => {
        setIsDataLoading(true);
        getCustomers()
            .then((response) => {
                console.log(response);
                setCustomerAllData(response.data.data.customers);
            })
            .finally(() => {
                setIsDataLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [reloadTableData]);

    const showModal = () => {
        setDrawerTitle("Add Customer");

        setDrawerVisible(true);
    };

    const handleAPIResponse = (response, type) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
            setReloadTableData(!reloadTableData);
            // Success
            if (type === ADD_CUSTOMER) {
                NotificationWithIcon(
                    "success",
                    "Customer Added",
                    "Customer added successfully!"
                );
            } else {
                NotificationWithIcon(
                    "success",
                    "Customer Updated",
                    "Customer updated successfully!"
                );
            }
        } else {
            NotificationWithIcon("error", "Something Went Wrong", data.message);
        }
        // Reset Form Fields
        customersForm.resetFields();
        setDrawerVisible(false);
    };

    const handleAddEdit = () => {
        customersForm
            .validateFields()
            .then((values) => {
                // Set Loading True
                setLoading(true);
                const formData = new window.FormData();
                Object.keys(values).forEach(key => {
                    if (values[key] !== undefined) {
                        formData.append(key, values[key]);
                    }
                });
                if (image && image instanceof File) {
                    formData.append("picture", image);
                }
                // Sending Request to API
                if (drawerTitle === ADD_CUSTOMER || drawerTitle === "Add Customer") {
                    addCustomer(formData)
                        .then((response) => {
                            console.log(response, "tttttt");
                            handleAPIResponse(response, ADD_CUSTOMER);
                        })
                        .catch((info) => {
                            console.log("API Failed:", info.response);
                        })
                        .finally(() => {
                            // Set Loading False
                            setLoading(false);
                        });
                } else {
                    updateCustomer(formData, values.id)
                        .then((response) => handleAPIResponse(response, null))
                        .catch((info) => {
                            console.log("API Failed:", info.response);
                        })
                        .finally(() => {
                            // Set Loading False
                            setLoading(false);
                        });
                }
            })
            .catch((info) => {
                console.log("API Failed:", info);
            });
    };

    const handleCancel = () => {
        // Reset Form Fields
        customersForm.resetFields();
        setDrawerVisible(false);
    };

    const handleEditModal = (record) => {
        customersForm.resetFields();
        setDrawerTitle("Edit Customer : " + record.company_name);
        // Set Form Fields
        setImage(record.picture);
        customersForm.setFieldsValue({
            id: record.id,
            status: record.status,
            company_name: record.company_name,
            picture: record.picture,
            type: record.type,
            nationality: record.nationality,
            tax_id: record.tax_id,
            address: record.address,
        });
        setDrawerVisible(true);
    };

    const handleDelete = (id) => {
        deleteCustomer(id)
            .then((response) => {
                let data = response.data || response.response.data;
                if (data.success === true) {
                    // Success
                    setReloadTableData(!reloadTableData);
                    NotificationWithIcon("success", "Customer Deleted", data.message);
                } else {
                    // Error
                    NotificationWithIcon("error", "Something Went Wrong", data.message);
                }
            })
            .catch((info) => {
                // Error
                NotificationWithIcon(
                    "error",
                    "Something Went Wrong",
                    info.response.data.message
                );
            });
    };

    const handleViewDetails = (customer) => {
        navigate(`/customers/${customer.id}`);
    };

    const changeHandler = (value) => {
        setValue(value);
    };
    const options = useMemo(() => countryList().getData(), []);

    const tableColumns = [
        {
            title: "#",
            key: "index",
            width: "5%",
            render: (text, record, index) => {
                return <>{index + 1}</>;
            },
        },
        {
            title: "Picture",
            align: "center",
            render: (record) => {
                console.log(record)
                return (
                    <>
                        {record.picture ? (
                                <img
                                    src={record.picture}
                                    alt="customer pictuer"
                                    style={{ maxWidth: "50px", borderRadius: "50%" }}
                                />
                            ) : (
                                record.type === 'Individual' ? (
                                    <UserOutlined style={{ fontSize: "50px" }} />
                                ) : (
                                    <BuildingIcon fontSize={"50px"} />
                                )
                            )}
                    </>
                );
            }
        },
        {
            title: "Company Name",
            dataIndex: "company_name",
        },
        {
            title: "Country",
            dataIndex: "nationality",
        },
        {
            title: "Type",
            render: (record) => {
                return (
                    <span style={{ display: "flex", alignItems: "center" }}>
                        {record.type === 'Individual' ? (
                                    <UserOutlined style={{ fontSize: "24px" }} />
                                ) : (
                                    <BuildingIcon fontSize={"26px"} />
                                )
                        } {record.type}
                    </span>
                );
            }
        },
        {
            title: "Address",
            dataIndex: "address",
        },
        {
            title: "Status",
            align: "center",
            render: (record) => {
                return (
                    <>
                        {record.status === "Active" ? (
                            <UnlockOutlined
                                title="Active"
                                style={{ color: "green", fontSize: "18px" }}
                            />
                        ) : (
                            <LockOutlined
                                title="Inactive"
                                style={{ color: "red", fontSize: "18px" }}
                            />
                        )}
                    </>
                );
            },
        },
        {
            title: "Action",
            align: "center",
            width: "150px",
            render: (record) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            type="link"
                            onClick={() => {
                                handleViewDetails(record);
                            }}
                            title="View Details"
                            style={{
                                padding: 6,
                                fontSize: "18px",
                                borderRadius: "5px",
                                backgroundColor: "rgba(82, 196, 26, 0.2)",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <EyeFilled style={{ color: "#52c41a" }} />
                        </Button>
                        <Button
                            type="link"
                            onClick={() => {
                                handleEditModal(record);
                            }}
                            title="Edit record"
                            style={{
                                padding: 6,
                                fontSize: "18px",
                                borderRadius: "5px",
                                backgroundColor: "rgba(24, 144, 255, 0.2)",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <EditTwoTone twoToneColor="#1890ff" />
                        </Button>
                        <Popconfirm
                            title="Are you sure to delete this entity?"
                            onConfirm={() => handleDelete(record.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteTwoTone
                                style={{
                                    padding: 6,
                                    fontSize: "18px",
                                    borderRadius: "5px",
                                    backgroundColor: "rgba(255, 0, 0, 0.2)",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                twoToneColor="#ff0000"
                                title="Delete Entity"
                            />
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <Card style={{ marginBottom: "20px" }}>
                <PageHeader
                    style={{ padding: "0" }}
                    title="Customer"
                    ghost={false}
                    extra={[
                        <Button type="primary" onClick={showModal} key="add-customer">
                            + Add Customer
                        </Button>,
                    ]}
                />
            </Card>
            <Card>
                <Table
                    columns={tableColumns}
                    dataSource={customerAllData}
                    loading={isDataLoading}
                    rowKey="id"
                    pagination={false}
                />
            </Card>
            <Drawer
                title={drawerTitle}
                placement="right"
                onClose={handleCancel}
                open={drawerVisible}
                width={"calc(100% - 200px)"}
            >
                <Form
                    name="add-form"
                    onFinish={handleAddEdit}
                    layout="vertical"
                    style={{ width: "100%", margin: "0" }}
                    form={customersForm}
                >
                    <Row
                        gutter={{
                            xs: 8,
                            sm: 16,
                            md: 20,
                            lg: 20,
                            xl: 20,
                            xxl: 20,
                        }}
                    >
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <ImageUpload
                                name="customer_picture"
                                callbackSetImageFile={setImage}
                                image={image}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item name="type" label="Type"
                            rules={[
                                {
                                    required: true,
                                    message: "Validate Type Required",
                                    whitespace: true,
                                },
                            ]}>
                                <Radio.Group buttonStyle="solid" key="type">
                                    <Radio.Button key="Individual" value="Individual">
                                        Individual
                                    </Radio.Button>
                                    <Radio.Button key="Company" value="Company">
                                        Company
                                    </Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                name="company_name"
                                label="Company Name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Validate Name Required",
                                        whitespace: true,
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                name="nationality"
                                label="Country"
                                rules={[
                                    {
                                        required: true,
                                        message: "Validate Country Required",
                                        whitespace: true,
                                    },
                                ]}
                                hasFeedback
                            >
                                <Select
                                    showSearch
                                    options={options}
                                    value={value}
                                    onChange={changeHandler}
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                name="tax_id"
                                label="Tax Id"
                                rules={[
                                    {
                                        required: true,
                                        message: "Validate ID Required",
                                        whitespace: true,
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                name="address"
                                label="Address"
                                rules={[
                                    {
                                        required: true,
                                        message: "Validate Address Required",
                                        whitespace: true,
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    {!(
                        drawerTitle === ADD_CUSTOMER || drawerTitle === "Add Customer"
                    ) && (
                            <Form.Item name="status" label="Status">
                                <Radio.Group buttonStyle="solid" key="status">
                                    <Radio.Button key="inactive" value="Inactive">
                                        Inactive
                                    </Radio.Button>
                                    <Radio.Button key="active" value="Active">
                                        Active
                                    </Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        )}
                    <Form.Item name="id" style={{ display: "none" }}>
                        <input type="hidden" />
                    </Form.Item>
                </Form>
                <Row justify="end" style={{ marginBottom: "20px" }}>
                    <Space size={20}>
                        <Button key="back" loading={loading} onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={handleAddEdit}
                        >
                            {drawerTitle === ADD_CUSTOMER ? "Add Customer" : "Update"}
                        </Button>
                    </Space>
                </Row>
            </Drawer>
        </>
    );
};

export default Customers;
